import React from 'react';
import ErrorPageLayout from '../../components/ui/ErrorPageLayout';

/**
 * A route component for an unexpected error page
 */
const UnexpectedError = ({ critical = false }) => {
  return (
    <ErrorPageLayout
      title="Unexpected Error"
      message="We're sorry, an unexpected error has occurred."
    />
  );
};

export default UnexpectedError;
