import React, { cloneElement, memo, useMemo, useState } from 'react';
import { usePopupState, bindTrigger } from 'material-ui-popup-state/hooks';
import useId from './useId';

/**
 * Helper to handle open a side sheet when a button is clicked
 * @fixme Make sure the accessibility for sheets is correct
 */
let SideSheetOpener = ({ name, button, sheet, disabled }) => {
  const popupId = useId(name);
  const popupState = usePopupState({ variant: 'popover', popupId });
  const [entered, setEntered] = useState(false);
  const shouldRender = popupState.isOpen || entered;
  const SlideProps = useMemo(
    () => ({
      appear: true,
      onEnter: () => {
        setEntered(true);
      },
      onExited: () => {
        setEntered(false);
      },
    }),
    []
  );

  return (
    <>
      {cloneElement(button, bindTrigger(popupState))}
      {shouldRender &&
        cloneElement(sheet, {
          id: popupState.popupId,
          open: popupState.isOpen && !disabled,
          onClose: popupState.close,
          onMouseLeave: popupState.onMouseLeave,
          SlideProps,
        })}
    </>
  );
};
SideSheetOpener = memo(SideSheetOpener);

export default SideSheetOpener;
