import React, { Suspense } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

/**
 * Loading state UI component for a route
 */
export const RouteLoadingPage = () => {
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      my={8}
    >
      <CircularProgress size={64} />
    </Box>
  );
};

/**
 * Suspense boundary for route page content loading states
 */
const RouteLoadingBoundary = ({ children }) => {
  return <Suspense fallback={<RouteLoadingPage />}>{children}</Suspense>;
};

export default RouteLoadingBoundary;
