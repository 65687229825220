import { DateTime } from 'luxon';
import { useIntl } from '../../intl';
import { useCallback } from 'react';
import { useDateFormat } from './CompanyPreferencesProvider';

/**
 * Hook providing date formatters that adhere to both Intl and company preferences
 */
export function useDateFormatters() {
  const { formatShortDate } = useIntl();
  const companyDateFormatPreference = useDateFormat();

  const formatDate = useCallback(
    date => {
      if (companyDateFormatPreference) {
        const dt =
          typeof date === 'string'
            ? DateTime.fromISO(date)
            : DateTime.fromJSDate(date);
        // @fixme Decide whether company format strings should match Luxon format strings
        return dt.toFormat(companyDateFormatPreference);
      }

      return formatShortDate(typeof date === 'string' ? new Date(date) : date);
    },
    [companyDateFormatPreference, formatShortDate]
  );

  return { formatDate };
}
