/**
 * WARNING: src/intl/ formatters are not compiled by create react app
 * In development they will not auto-recompile when changed.
 * If you are edit this file during dev you will need to run `yarn build-intl` to test your changes.
 */
import * as Globalize from 'globalize/dist/globalize-runtime';

/**
 * Intl Format a short time string e.g. "9:30 AM" in en-US
 */
export const formatTime = () => Globalize.dateFormatter({ time: 'short' });

/**
 * Boolean indicating whether locale uses 12h time or 24h time
 */
export const is12hTime = () =>
  (Globalize as any)
    .formatDateToParts(new Date(), {
      time: 'short',
    })
    .some((part: any) => part.type === 'dayperiod');

/**
 * Luxon compatible format string for the locale's time format e.g. "" in en-US
 */
export const timeFormatString = (): string => {
  const parts = (Globalize as any).formatDateToParts(new Date(), {
    time: 'short',
  });

  return parts
    .map((part: any) => {
      switch (part.type) {
        case 'literal':
          return part.value.replace(/([a-z]+)/, "'$1'");
        case 'hour':
          return parts.some((part: any) => part.type === 'dayperiod')
            ? 'hh'
            : 'HH';
        case 'minute':
          return 'mm';
        case 'second':
          return 'ss';
        case 'dayperiod':
          return 'a';
        default:
          return '';
      }
    })
    .join('');
};
