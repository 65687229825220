import React, { useMemo } from 'react';
import {
  createMuiTheme,
  useMediaQuery,
  colors,
  MuiThemeProvider,
  lighten,
  CssBaseline,
} from '@material-ui/core';
import { brandColors } from './brand';
import useConstant from 'use-constant';
import { useLocaleInfo } from '../../intl';

/**
 * Hook to determine whether we should be in dark or light mode
 */
function useDarkMode() {
  let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const devDarkMode = useConstant(() => {
    if (process.env.NODE_ENV === 'development') {
      // In development mode switch between light/dark mode at random
      // to help avoid scenarios where someone commits changes
      // that only look correct in one of the modes.
      let prefersDarkMode = Boolean(Math.floor(Math.random() * 2));

      // However allow developers to use sessionStorage to override this
      // to temporarily work on fixing light/dark mode specific bugs
      // > sessionStorage.setItem('theme-mode-override', 'light')
      // > sessionStorage.setItem('theme-mode-override', 'dark')
      const themeModeOverride = sessionStorage.getItem('theme-mode-override');
      if (themeModeOverride) {
        prefersDarkMode = themeModeOverride === 'dark';
      }

      return prefersDarkMode;
    }
  }, []);

  if (process.env.NODE_ENV === 'development') {
    prefersDarkMode = devDarkMode;
  }

  return prefersDarkMode;
}

export const ThemeProvider = ({ theme: themeTypeOverride, children }) => {
  const prefersDarkMode = useDarkMode();
  const { dir } = useLocaleInfo();

  let themeType = prefersDarkMode ? 'dark' : 'light';
  if (themeTypeOverride) {
    themeType = themeTypeOverride;
  }

  const theme = useMemo(() => {
    const coreTheme = createMuiTheme({
      direction: dir,
      palette: {
        type: themeType,
        primary: {
          light: lighten(brandColors.teal, 0.85),
          main: brandColors.teal,
        },
        secondary: { main: brandColors.gold },
        warning: {
          light: lighten(brandColors.gold, 0.85),
          main: brandColors.gold,
          contrastText: '#795906',
        },
        error: {
          main: colors.red['A100'],
        },
        background: {
          globalShell: brandColors.grey,
          localShell: lighten(brandColors.grey, 0.1),
        },
        contrastText: 4.5,
      },
      typography: {
        useNextVariants: true,
      },
    });

    return createMuiTheme({
      ...coreTheme,
      overrides: {
        ...coreTheme.overrides,
        MuiTypography: {
          body1: {
            fontSize: '0.9rem',
          },
        },
        MuiAppBar: {
          colorDefault: {
            backgroundColor: coreTheme.palette.background.globalShell,
            color: coreTheme.palette.getContrastText(
              coreTheme.palette.background.globalShell
            ),
          },
        },
      },
    });
  }, [themeType, dir]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
