import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import StatusIndicator from './StatusIndicator';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'rows',
  },
  name: {
    marginLeft: theme.spacing(2),
    marginTop: 6,
  },
}));

const Attended = props => {
  const classes = useStyles();
  const { status, name } = props;
  return (
    <div className={classes.root}>
      <StatusIndicator status={status} />
      <Typography className={classes.name}>{name}</Typography>
    </div>
  );
};
export default Attended;
