import { useContext, useState, useEffect } from 'react';
import localeInfo, { LocaleInfo } from './localeInfo';
import { IntlAPI } from './api';
import { LocaleContext, UpdateLocale, UpdateLocaleContext } from './context';
import { getLocaleAPI, defaultLocaleAPI } from './loader';

/**
 * Hook that provides locale info for the current locale
 */
export function useLocaleInfo(): LocaleInfo {
  const locale = useContext(LocaleContext);
  return localeInfo[locale];
}

/**
 * Hook that provides a function to update the locale
 */
export function useUpdateLocale(): UpdateLocale {
  return useContext(UpdateLocaleContext);
}

/**
 * Hook that provides a copy of this Intl API and ensures a component
 * is re-rendered if the locale changes.
 */
export function useIntl(): IntlAPI {
  const locale = useContext(LocaleContext);
  const [intl, setIntl] = useState<IntlAPI | null>(null);
  useEffect(() => {
    let aborted = false;
    // For now we load the locale via a promise result
    // In the we should be able to just use
    // > setIntl(getLocaleAPI(locale));
    // And Suspense will take care of loading
    getLocaleAPI(locale).then(intl => {
      if (!aborted) {
        setIntl(intl);
      }
    });

    return () => {
      aborted = true;
    };
  }, [locale]);

  // For now we need to return a hardcoded English (US) locale when not ready
  // In the future Suspense will allow us to throw the import promise
  if (!intl) return defaultLocaleAPI;
  return intl;
}
