import React, { useState } from 'react';
import { makeStyles, IconButton, Tooltip } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import AttachIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import PeopleIcon from '@material-ui/icons/People';
import EmailIcon from '@material-ui/icons/Email';
import SideSheetOpener from '../utils/SideSheetOpener';
import { useHistory } from 'react-router-dom';
import Confirmation from './Confirmation';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
}));

// @fixme Component name is too generic and best practices generally recommend composition over all-in-one components like this
const ListActions = props => {
  const classes = useStyles();
  const {
    type,
    params,
    editSheet,
    viewOnly,
    canDelete,
    handleDelete,
    meetingItem,
  } = props;
  const history = useHistory();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const openConfirmationDialog = () => {
    setConfirmationDialogOpen(true);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const deleteMeetingItem = () => {
    openConfirmationDialog();
  };

  const all = (
    <div className={classes.root}>
      <IconButton>
        <CopyIcon />
      </IconButton>
      <IconButton>
        <EditIcon />
      </IconButton>
      <IconButton>
        <AttachIcon />
      </IconButton>
      <IconButton>
        <DeleteIcon />
      </IconButton>
      <IconButton>
        <LaunchIcon />
      </IconButton>
    </div>
  );

  const editDelete = (
    <div className={classes.root}>
      <IconButton>
        <EditIcon />
      </IconButton>
      <IconButton>
        <DeleteIcon />
      </IconButton>
    </div>
  );

  const meetingRoute = routes => {
    const confirmationTitle = 'Delete meeting?';
    const confirmationContent = `Are you sure you want to delete ${
      params?.meeting?.name
    } #${params?.meeting?.meetingNumber
      ?.toString()
      .padStart(3, '0')} in this series?`;
    return (
      <div className={classes.root}>
        <Confirmation
          dialogTitle={confirmationTitle}
          dialogContent={confirmationContent}
          dialogOpen={confirmationDialogOpen}
          handleClose={closeConfirmationDialog}
          primaryButtonLabel="Delete"
          params={params?.meeting?.id}
          action={params.handleDelete}
        />
        <Tooltip title="Minutes">
          <IconButton
            aria-label="Open minutes"
            onClick={e => {
              history.push({
                pathname: routes.meetingMinutesRoute,
                state: { viewOnly: viewOnly },
              });
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Attendees">
          <IconButton
            aria-label="Open attendees"
            onClick={e => {
              history.push({
                pathname: routes.meetingAttendeesRoute,
                state: { viewOnly: viewOnly },
              });
            }}
          >
            <PeopleIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Correspondence">
          <IconButton
            aria-label="Open correspondence"
            onClick={e => {
              history.push({
                pathname: routes.meetingCorrespondenceRoute,
                state: { viewOnly: viewOnly },
              });
            }}
          >
            <EmailIcon />
          </IconButton>
        </Tooltip>
        {!!params?.canDelete && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={openConfirmationDialog}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  const editMeetingItem = (
    <div className={classes.root}>
      <Confirmation
        dialogTitle={'Delete Meeting Item'}
        dialogContent={`Are you sure you want to delete: ${meetingItem?.subject}?`}
        dialogOpen={confirmationDialogOpen}
        handleClose={closeConfirmationDialog}
        params={meetingItem?.id}
        action={handleDelete}
      />
      <SideSheetOpener
        button={
          <IconButton disabled={viewOnly}>
            <EditIcon />
          </IconButton>
        }
        sheet={editSheet}
      />
      {canDelete && (
        <IconButton onClick={deleteMeetingItem}>
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  );

  let actions = '';

  switch (type) {
    case 'all':
      actions = all;
      break;
    case 'editDelete':
      actions = editDelete;
      break;
    case 'route':
      actions = params.routes && meetingRoute(params.routes);
      break;
    case 'editMeetingItem':
      actions = editMeetingItem;
      break;
    default:
  }
  return actions;
};

export default ListActions;
