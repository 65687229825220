import React, { cloneElement, forwardRef } from 'react';
import { useLocation, NavLink, matchPath } from 'react-router-dom';
import {
  normalizeToLocation,
  resolveToLocation,
} from 'react-router-dom/modules/utils/locationUtils';
import { makeStyles, MenuItem } from '@material-ui/core';

const useStyles = makeStyles(
  theme => ({
    root: {},
    selected: {},
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'NavMenuItem' }
);

/**
 * A copy of the code used by react-router-dom's NavLink
 * We can't use NavLink in such a way that we can pass selected to MenuItem as MUI requires
 */
function useIsActive(
  to,
  { isActive: isActiveProp = false, exact = false, strict = false }
) {
  const currentLocation = useLocation();
  if (!to) return false;
  const toLocation = normalizeToLocation(
    resolveToLocation(to, currentLocation),
    currentLocation
  );
  const { pathname: path } = toLocation;
  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

  const match = escapedPath
    ? matchPath(currentLocation.pathname, {
        path: escapedPath,
        exact,
        strict,
      })
    : null;
  const isActive = !!(isActiveProp
    ? isActiveProp(match, currentLocation)
    : match);

  return isActive;
}

/**
 * A MenuItem for navigation <Menu> with icon support
 * and NavLink router integration.
 */
let NavMenuItem = (props, ref) => {
  const classes = useStyles(props);
  const { to, icon, children, className, ...itemProps } = props;
  const { isActive: isActiveProp, exact, strict } = itemProps;
  const isActive = useIsActive(to, { isActive: isActiveProp, exact, strict });

  const navLinkProps = to && {
    component: NavLink,
    selected: isActive,
    to: to,
  };

  return (
    <MenuItem {...itemProps} classes={{ root: classes.root }} {...navLinkProps}>
      {icon && cloneElement(icon, { className: classes.icon })}
      {children}
    </MenuItem>
  );
};
NavMenuItem = forwardRef(NavMenuItem);

export default NavMenuItem;
