import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
  useMemo,
} from 'react';

const AuthDispatchContext = createContext(() => {
  throw new Error('<AuthRoot> is missing');
});
const AuthStateContext = createContext(null);

const guest = {
  isGuest: true,
};

/**
 * Root provider component for auth data
 */
export const AuthRoot = ({ children }) => {
  const [authState, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_TOKEN':
          state = {
            ...state,
            token: action.token,
          };
          break;
        case 'SET_USER':
          state = {
            ...state,
            user: action.user,
            can: action.can || [],
          };
          break;
        case 'LOGOUT':
          state = {
            token: null,
            user: guest,
            can: [],
          };
          break;
        default:
          throw new Error(`Unknown action type ${action.type}`);
      }

      return state;
    },
    { token: null, user: guest, can: [] }
  );

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={authState}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

/**
 * Root provider component for externally provided auth state
 */
export const MockAuthRoot = ({ user, can, onLogOut, children }) => {
  const dispatch = useCallback(
    action => {
      switch (action.type) {
        case 'LOGOUT':
          onLogOut();
          break;
        default:
          throw new Error(`Unknown action type ${action.type}`);
      }
    },
    [onLogOut]
  );
  const authState = useMemo(
    () => ({
      user,
      can,
    }),
    [user, can]
  );

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={authState}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

/**
 * Hook providing auth actions like login, logout, and refresh actions
 */
export function useAuthActions() {
  const dispatch = useContext(AuthDispatchContext);
  const login = useCallback(async () => {
    // await {Get token}
    // dispatch({type: 'SET_TOKEN'})
    // await {Fetch user}
    // dispatch({type: 'SET_USER'})
  }, []);
  const logout = useCallback(async () => {
    dispatch({ type: 'LOGOUT' });
  }, [dispatch]);

  return { login, logout };
}

/**
 * Hook providing the current user
 */
export function useUser() {
  const { user } = useContext(AuthStateContext);
  return user;
}

/**
 * Hook providing the permissions array for the current user
 */
export function useUserCan() {
  const { can } = useContext(AuthStateContext);
  return can;
}
