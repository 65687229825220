import React from 'react';
import { GqlApiProvider } from './components/utils/api/graphql/client';
import CompanyPreferencesProvider from './components/utils/CompanyPreferencesProvider';
import { ProjectRoot } from './components/utils/project';
import { HeaderContextRoot } from './components/layout/HeaderContext';
import SiteLoadingBoundary from './components/ui/loading-boundaries/SiteLoadingBoundary';
import nestProviders from './components/utils/nestProviders';

/**
 * App context roots
 */
const AppRoot = ({ projectRoot, children }) => {
  return nestProviders(
    <GqlApiProvider />,
    <CompanyPreferencesProvider />,
    projectRoot || <ProjectRoot />,
    <HeaderContextRoot />,
    <SiteLoadingBoundary />,
    children
  );
};

export default AppRoot;
