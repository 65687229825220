import React, { memo } from 'react';
import asErrorBoundary from '../asErrorBoundary';
import UnexpectedError from '../../../routes/errors/UnexpectedError';

/**
 * Error Boundary for routes
 */
const RouteError = ({ error }) => {
  return <UnexpectedError />;
};

let RouteErrorBoundary = asErrorBoundary(RouteError);
RouteErrorBoundary = memo(RouteErrorBoundary);

export default RouteErrorBoundary;
