import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useDateFormatters } from '../utils/date';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold',
  },
}));

const AgendaItem = props => {
  const { meetingItemProperty, agendaItem, agendaDesc, actionItems } = props;
  const classes = useStyles();

  const { formatDate } = useDateFormatters();

  let items = [];

  if (actionItems) {
    try {
      items = JSON.parse(actionItems);
      // @fixme ISO timestamps within 7979 years are sortable, so parsing is unnecessary
      items.forEach(item => {
        const date = new Date(item.action_date);
        const milliseconds = date.getTime();
        item['time'] = milliseconds;
      });
      // @fixme Just use lodash's sortBy to sort by properties and .reverse() can be used if it's in the wrong order
      items.sort((a, b) => {
        return a.time - b.time;
      });
    } catch {
      console.log('Error parsing action item data');
    }
  }

  return (
    <>
      {meetingItemProperty?.subject?.configuration?.visible === 1 && (
        <Typography variant="subtitle1" className={classes.bold}>
          {agendaItem}
        </Typography>
      )}
      {meetingItemProperty?.description?.configuration?.visible === 1 && (
        <Typography variant="subtitle2" color="textSecondary">
          {agendaDesc}
        </Typography>
      )}
      {items.map((item, index) => (
        <div key={index}>
          {meetingItemProperty?.actionTaken?.configuration?.visible === 1 && (
            <Typography>
              <span className={classes.bold}>
                {formatDate(item.action_date)}
              </span>
              <span>{' - ' + item.action_taken}</span>
            </Typography>
          )}
        </div>
      ))}
    </>
  );
};

export default AgendaItem;
