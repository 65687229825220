import React, { memo } from 'react';
import asErrorBoundary from '../asErrorBoundary';

/**
 * Error Boundary that catches errors that cannot be caught by other error boundaries
 *
 * Errors that are caught by this boundary are either:
 * - Emitted from a provider above the ThemeProvider
 * - Emitted by a Material UI component and thus re-thrown in other error boundaries
 *
 * Because of that this error boundary cannot use the theme or any MUI components.
 */
const RootError = ({ error, critical = false }) => {
  return (
    <div>
      <h1>Critical Error</h1>
      <p>We're sorry, an unexpected critical error has occurred.</p>
    </div>
  );
};

let RootErrorBoundary = asErrorBoundary(RootError);
RootErrorBoundary = memo(RootErrorBoundary);

export default RootErrorBoundary;
