import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { projectList } from '../../data/Data';

const ProjectDispatchContext = createContext(() => {
  throw new Error('<ProjectRoot> is missing');
});
const ProjectStateContext = createContext(null);

/**
 * Root provider component for Project state
 */
export const ProjectRoot = ({ children }) => {
  const [projectState, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_PROJECT':
          state = {
            ...state,
            project: action.project,
          };
          break;
        default:
          throw new Error(`Unknown action type ${action.type}`);
      }

      return state;
    },
    { project: projectList[0].id, projects: projectList }
  );

  return (
    <ProjectDispatchContext.Provider value={dispatch}>
      <ProjectStateContext.Provider value={projectState}>
        {children}
      </ProjectStateContext.Provider>
    </ProjectDispatchContext.Provider>
  );
};

/**
 * Root provider component for externally provided Project state
 * projects = active projects
 */
export const MockProjectRoot = ({
  allProjects,
  projects,
  project,
  onChangeProject,
  children,
}) => {
  const dispatch = useCallback(
    action => {
      switch (action.type) {
        case 'SET_PROJECT':
          onChangeProject(action.project);
          break;
        default:
          throw new Error(`Unknown action type ${action.type}`);
      }
    },
    [onChangeProject]
  );
  const projectState = useMemo(() => ({ project, projects, allProjects }), [
    project,
    projects,
    allProjects
  ]);

  return (
    <ProjectDispatchContext.Provider value={dispatch}>
      <ProjectStateContext.Provider value={projectState}>
        {children}
      </ProjectStateContext.Provider>
    </ProjectDispatchContext.Provider>
  );
};

/**
 * Hook providing project actions like setProject
 */
export function useProjectActions() {
  const dispatch = useContext(ProjectDispatchContext);
  const setProject = useCallback(
    project => {
      dispatch({ type: 'SET_PROJECT', project });
    },
    [dispatch]
  );

  return { setProject };
}

/**
 * Hook providing the available projects list
 */
export function useProjects() {
  const { projects } = useContext(ProjectStateContext);
  return projects;
}

/**
 * Hook providing the currently active project
 */
export function useProject() {
  const { allProjects } = useContext(ProjectStateContext);

  const location = useLocation();
  const pathname = location?.pathname;

  const regex = /\W*(project\/\d+)\W*/;
  const path = regex.exec(pathname);

  let project;

  if (path) {
    const group = path[1];
    const projectId = group.split('/')[1];
    project = parseInt(projectId);
  }

  return useMemo(() => allProjects?.find(info => info.id === project), [
    allProjects,
    project,
  ]);
}
