import {
  meetingGroups,
  upcomingMeetingList,
  meetingAttendee,
  meetingList,
  meetingGroupAttendee,
  meetingGroupItem,
  user,
  meetingSeriesUserPermission,
  lastMeeting,
  company,
  meetingItem,
  category,
  meeting,
  dummySubmittalTypes,
  dummySubmittalsRows,
  dummyTradeNames,
  dummyCostCodes,
  dummySubmittalPropertiesTable,
  dummyContacts,
  dummyProjectContacts,
  dummyTradeContacts,
  dummySubmittalsLogEntries,
  dummySubmittalTypeLog,
  dummySubmittalLogAttachments,
  dummyUsedSubmittalTypes,
  dummyMeetingItemProperties,
} from '../../../../data/Data';
import { MockResponse } from '../api.mock';

/**
 * Mock data definitions for API requests
 *
 * - Key is an API request path,
 *   can contain pattern strings like those used in the router, e.g. `:id`
 * - Value is a function that returns the mock data
 *   - First argument is a params object containing values for any params in the url (e.g. `:id`)
 *   - Second argument is the query parameters object
 */
export const mockResponses: Record<string, MockResponse> = {
  'GET /api/v1/user': () => user,
  'GET /api/v1/company': () => company,
  'GET /api/v1/mm/meeting-group/project/:id': () => meetingGroups,
  'GET /api/v1/mm/meeting/project/:id/upcoming': () => upcomingMeetingList,
  'GET /api/v1/mm/meeting/group/:id/last': () => lastMeeting,
  'GET /api/v1/mm/meeting/group/:id': () => meetingList,
  'GET /api/v1/mm/meeting/:id': () => meeting,
  'GET /api/v1/mm/meeting-attendee/meeting/:id': () => meetingAttendee,
  'GET /api/v1/mm/meeting-attendee/group/:id': () => meetingGroupAttendee,
  'GET /api/v1/mm/meeting-attendee/meeting/:id/permissions': () =>
    meetingSeriesUserPermission,
  'GET /api/v1/mm/meeting-item/group/:id': () => meetingGroupItem,
  'GET /api/v1/mm/meeting-item/meeting/:id': () => meetingItem,
  'GET /api/v1/mm/meeting-item/meeting/:id/categories': () => category,
  'POST /api/v1/mm/meeting-group': (params, query, body) => ({
    id: 1,
    name: body.name,
    projectId: body.entry_id,
  }),
  'POST /api/v1/mm/meeting': (params, query, body) => ({
    id: 1,
    group_id: body.group_id,
    meeting_number: 1,
    title: body.title,
    status: 'Open',
    location: body.location,
    start_time: body.start_time,
    end_time: body.end_time,
    next_meeting_id: null,
    run_number: 1,
  }),
  'PATCH /api/v1/mm/meeting/:id': (params, query, body) => ({
    id: parseInt(params.id),
    title: body.title,
    location: body.location,
    start_time: body.start_time,
    end_time: body.end_time,
  }),
  /*Patch statement to update meeting name */
  'PATCH /api/v1/mm/meeting/group/:id': (params, query, body) => ({
    name: body.name,
  }),
  'POST /api/v1/mm/meeting-attendee': (params, query, body) => ({
    status: '200',
    message: 'success',
  }),
  'POST /api/v1/mm/meeting-item/meeting/category': (params, query, body) => ({
    category: body.category,
    meeting_id: body.meetingId,
  }),
  'PATCH /api/v1/mm/meeting-attachment/meeting/:meetingId/:reviewType': (
    params,
    query,
    body
  ) => ({
    meeting_attachment: {
      name: body.pdf,
      type: body.type,
      comments: body.message,
    },
    cc_recipients: body.cc,
  }),
  'GET /api/v1/submittals/submittals-type/project/:id': () =>
    dummySubmittalTypes,
  'GET /api/v1/submittals/submittals-checklist/project/:id': () =>
    dummySubmittalsRows,
  'GET /api/v1/company/project/:id': () => dummyTradeNames,
  'GET /api/v1/system-code/project/:id': () => dummyCostCodes,
  'GET /api/v1/submittals/submittals-type/project/:id/properties': () =>
    dummySubmittalPropertiesTable,
  'GET /api/v1/contact': () => dummyContacts,
  'GET /api/v1/contact/project/:id': () => dummyProjectContacts,
  'GET /api/v1/contact/company/:id': () => dummyTradeContacts,
  'GET /api/v1/submittals/submittals-log/project/:id': () =>
    dummySubmittalsLogEntries,
  'GET /api/v1/submittals/submittals-log/project/:id/:submittalType': () =>
    dummySubmittalTypeLog,
  'GET /api/v1/submittals/submittals-log/entry/:id/attachments': () =>
    dummySubmittalLogAttachments,
  'GET /api/v1/submittals/submittals-checklist/project/:id/submittals': () =>
    dummyUsedSubmittalTypes,
  'GET /api/v1/property/type/:type': () => dummyMeetingItemProperties,
};
