import React, { Suspense } from 'react';
import { Container, Box, CircularProgress } from '@material-ui/core';

/**
 * Whole page loading state UI component
 */
export const SiteLoadingPage = () => {
  return (
    <Container>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        // Workaround till we have a flex root
        height="100vh"
      >
        <CircularProgress size={64} />
      </Box>
    </Container>
  );
};

/**
 * Suspense boundary for whole page loading states
 */
const SiteLoadingBoundary = ({ children }) => {
  return <Suspense fallback={<SiteLoadingPage />}>{children}</Suspense>;
};

export default SiteLoadingBoundary;
