import clsx from 'clsx';
import React, { FC } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import NeutralIcon from '@material-ui/icons/RemoveCircleRounded';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  positive: {
    backgroundColor: theme.palette.primary.main,
  },
  neutral: {},
  negative: {
    backgroundColor: theme.palette.error.main,
  },
}));

const icons = {
  positive: <CheckIcon />,
  neutral: <NeutralIcon />,
  negative: <WarningIcon />,
};

const labels = {
  positive: 'Positive',
  neutral: 'neutral',
  negative: 'negative',
};

export interface StatusIndicatorProps {
  status: 'positive' | 'neutral' | 'negative';
}

/**
 * Status indicator circle for meeting minutes status.
 */
const StatusIndicator: FC<StatusIndicatorProps> = props => {
  const { status } = props;
  const classes = useStyles();

  if (!icons.hasOwnProperty(status))
    throw new Error(`Unknown status ${status}`);

  return (
    <Avatar
      aria-label={labels[status]}
      className={clsx(classes.root, classes[status])}
    >
      {icons[status]}
    </Avatar>
  );
};

export default StatusIndicator;
