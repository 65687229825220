import { cloneElement } from 'react';

/**
 * Helper for working with React provider components
 * That replaces a <A><B>...</B></A> with nestProviders(<A />, <B />, children)
 */
export default function nestProviders(...providers) {
  return providers
    .slice()
    .reverse()
    .reduce((child, parent) => cloneElement(parent, parent.props, child));
}
