import clsx from 'clsx';
import React, { FC } from 'react';
import { makeStyles, Typography, lighten } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'center',
    fontSize: '0.7rem',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
  },
  //@fixme temporary classes for the open, closed, and info statuses
  open: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  closed: {
    backgroundColor: lighten(theme.palette.error.light, 0.4),
    color: theme.palette.error.contrastText,
  },
  info: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.65),
    color: theme.palette.primary.contrastText,
  },
  none: {},
  low: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  medium: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.65),
    color: theme.palette.primary.contrastText,
  },
  high: {
    backgroundColor: lighten(theme.palette.error.light, 0.4),
    color: theme.palette.error.contrastText,
  },
  critical: {
    backgroundColor: lighten(theme.palette.error.light, 0.2),
    color: theme.palette.error.contrastText,
  },
  number: {
    marginRight: theme.spacing(1),
  },
}));

const labels = {
  none: 'None',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  critical: 'Critical',
  //@fixme temporary labels for the open, closed, and info statuses
  open: 'Open',
  closed: 'Closed',
  info: 'Info',
};

export interface PriorityIndicatorProps {
  //@fixme temporary props for the open, closed, and info statuses
  priority:
    | 'none'
    | 'low'
    | 'medium'
    | 'high'
    | 'critical'
    | 'open'
    | 'closed'
    | 'info';
  count?: number;
}

/**
 * Priority indicator circle for meeting minutes priority.
 */
const PriorityIndicator: FC<PriorityIndicatorProps> = props => {
  const { priority, count } = props;
  const classes = useStyles();

  if (!labels.hasOwnProperty(priority))
    throw new Error(`Unknown priority ${priority}`);

  return (
    <div className={clsx(classes.root, classes[priority])}>
      {count && (
        <Typography variant="overline" className={classes.number}>
          {count}
        </Typography>
      )}

      <Typography variant="overline">{labels[priority]}</Typography>
    </div>
  );
};

export default PriorityIndicator;
