import React, { useMemo } from 'react';
import { List, Typography } from '@material-ui/core';
import MenuItem from '../ui/MenuItem';
import SubmittalIcon from '@material-ui/icons/HowToVote';
import MeetingIcon from '@material-ui/icons/DateRange';
import InstructionsIcon from '@material-ui/icons/ChromeReaderMode';
import ContactsIcon from '@material-ui/icons/Contacts';
import RfiIcon from '@material-ui/icons/PostAdd';
import DashboardIcon from '@material-ui/icons/Dashboard';
import useId from '../utils/useId';
import UsersIcon from '@material-ui/icons/SupervisedUserCircle';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import BuildIcon from '@material-ui/icons/Build';
import OfficeBuildingIcon from 'mdi-material-ui/OfficeBuilding';
import { useProject } from '../utils/project';
import { useUserCan } from '../utils/auth';

export const drawerWidth = 240;

const NavigationContents = props => {
  const titleId = useId('sitenav-title');
  const can = useUserCan();
  const project = useProject();
  const projectId = project?.id;

  const active = useMemo(() => {
    if (!projectId) {
      if (window.location.pathname.includes('/correspondence')) {
        return 'correspondence';
      } else if (window.location.pathname.includes('/companies')) {
        return 'companies';
      } else if (window.location.pathname.includes('/users')) {
        return 'users';
      } else {
        return 'settings';
      }
    } else {
      if (window.location.pathname.includes('/contacts')) {
        return 'contacts';
      } else if (window.location.pathname.includes('/submittals')) {
        return 'submittals';
      } else if (window.location.pathname.includes('/si')) {
        return 'site-instructions';
      } else if (window.location.pathname.includes('/rfi')) {
        return 'rfis';
      } else if (window.location.pathname.includes('mm')) {
        return 'meetings';
      } else {
        return 'dashboard';
      }
    }
  }, [projectId]);

  const menuData = useMemo(() => {
    if (!projectId) {
      if (Object.values(can).includes('companyAdmin')) {
        return [
          {
            id: 'settings',
            primary: 'Settings',
            icon: <BuildIcon />,
            link: '/settings',
          },
          {
            id: 'correspondence',
            primary: 'Correspondence',
            icon: <FormatListBulletedIcon />,
            link: '/correspondence/signature',
          },
          {
            id: 'companies',
            primary: 'Companies',
            icon: <OfficeBuildingIcon />,
            link: '/companies',
          },
          {
            id: 'users',
            primary: 'Users',
            icon: <UsersIcon />,
            link: '/users',
          },
        ];
      } else {
        return [
          {
            id: 'correspondence',
            primary: 'Correspondence',
            icon: <FormatListBulletedIcon />,
            link: '/correspondence/signature',
          },
          {
            id: 'companies',
            primary: 'Companies',
            icon: <OfficeBuildingIcon />,
            link: '/companies',
          },
        ];
      }
    }
    return [
      {
        id: 'dashboard',
        primary: 'Dashboard',
        icon: <DashboardIcon />,
        link: `/project/${projectId}`,
      },
      {
        id: 'contacts',
        primary: 'Contacts',
        icon: <ContactsIcon />,
        link: `/project/${projectId}/contacts`,
      },
      {
        id: 'submittals',
        primary: 'Submittals',
        icon: <SubmittalIcon />,
        link: `/project/${projectId}/submittals/logs`,
      },
      {
        id: 'site-instructions',
        primary: 'Site Instructions',
        icon: <InstructionsIcon />,
        link: `/project/${projectId}/si/logs`,
      },
      {
        id: 'rfis',
        primary: 'RFIs',
        icon: <RfiIcon />,
        link: `/project/${projectId}/rfi/logs`,
      },
      {
        id: 'meetings',
        primary: 'Meetings',
        icon: <MeetingIcon />,
        link: `/project/${projectId}/mm`,
      },
    ];
  }, [projectId, can]);

  return (
    <nav id="sitenav" aria-labelledby={titleId}>
      <Typography id={titleId} component="h2" variant="srOnly">
        Site Navigation
      </Typography>
      <List>
        {menuData.map(item => {
          return (
            // @fixme Merge MenuItem with NavMenuItem
            <MenuItem
              key={item.id}
              primary={item.primary}
              icon={item.icon}
              active={active}
              page={item.id}
              link={{ pathname: item.link }}
            />
          );
        })}
      </List>
    </nav>
  );
};

export default NavigationContents;
