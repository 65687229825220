import React from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import Layout from './components/layout/Layout';
import AppRoot from './AppRoot';
import { ThemeProvider } from './components/utils/theme';
import { MockAuthRoot } from './components/utils/auth';
import UtilsProvider from './UtilsProvider';
import { LocaleProvider } from './intl';
import LocaleUtilsProvider from './LocaleUtilsProvider';
import nestProviders from './components/utils/nestProviders';
import RootErrorBoundary from './components/ui/error-boundaries/RootErrorBoundary';
import SiteErrorBoundary from './components/ui/error-boundaries/SiteErrorBoundary';
import Routes from './Routes';

const App = () => {
  return nestProviders(
    <RootErrorBoundary />,
    <UtilsProvider />,
    <LocaleProvider />,
    <LocaleUtilsProvider />,
    <ThemeProvider />,
    <SiteErrorBoundary critical />,
    <MockAuthRoot
      user={{ isGuest: false, username: 'admin' }}
      can={['systemAdmin', 'companyAdmin', 'employee']}
    />,
    <AppRoot />,
    <Router basename={process.env.PUBLIC_URL} />,
    <SiteErrorBoundary />,
    <Layout />,
    <Routes extraRoutes={<Redirect exact from="/" to="/mm" />} />
  );
};

export default App;
