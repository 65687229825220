/**
 * WARNING: src/intl/ formatters are not compiled by create react app
 * In development they will not auto-recompile when changed.
 * If you are edit this file during dev you will need to run `yarn build-intl` to test your changes.
 */
import * as Globalize from 'globalize/dist/globalize-runtime';

/**
 * Intl Format a long date string e.g. "September 14, 2020"
 */
export const formatLongDate = () => Globalize.dateFormatter({ date: 'long' });
/**
 * Intl Format a medium date string e.g. "Sep 14, 2020"
 */
export const formatMediumDate = () =>
  Globalize.dateFormatter({ date: 'medium' });
/**
 * Intl Format a short date string e.g. "9/14/2020" in en-US
 */
export const formatShortDate = () => Globalize.dateFormatter({ date: 'short' });

/**
 * Luxon compatible format string for the locale's short date format e.g. "MM/dd/yyyy" in en-US
 */
export const shortDateFormatString = (): string => {
  // @fixme Globalize's DefinitelyTyped definitions need definitions for the parts handlers
  const parts = (Globalize as any).formatDateToParts(new Date(), {
    date: 'short',
  });
  const tokens: any = { year: 'yyyy', month: 'MM', day: 'dd' };

  return parts
    .map((part: any) => {
      if (part.type === 'literal')
        return part.value.replace(/([a-z]+)/, "'$1'");
      return tokens[part.type] ?? '';
    })
    .join('');
};

/**
 * Get the Intl Formatted name of the Month in a date e.g. "September"
 */
export const formatMonth = () => Globalize.dateFormatter({ skeleton: 'LLLL' });
/**
 * Get the short Intl Formatted name of the Month in a date e.g. "Sep"
 */
export const formatShortMonth = () =>
  Globalize.dateFormatter({ skeleton: 'LLL' });

/**
 * Get the Intl Formatted day of the month in a date e.g. "14"
 */
export const formatMonthDate = () => Globalize.dateFormatter({ skeleton: 'd' });

/**
 * Get the Intl Formatted year in a date e.g. "2020"
 */
export const formatYear = () => Globalize.dateFormatter({ skeleton: 'y' });
