import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import asErrorBoundary from '../asErrorBoundary';

/**
 * Error Boundary for errors that happen in the header contents provided by a page
 */
const HeaderError = ({ error }) => {
  return (
    <Typography component="p" variant="subtitle1" color="error">
      We're sorry, an unexpected error has occurred in the page header's
      contents.
    </Typography>
  );
};

let HeaderErrorBoundary = asErrorBoundary(HeaderError);
HeaderErrorBoundary = memo(HeaderErrorBoundary);

export default HeaderErrorBoundary;
