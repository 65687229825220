import React from 'react';
import StatusIndicator from '../components/ui/StatusIndicator';
import PriorityIndicator from '../components/ui/PriorityIndicator';
import Attended from '../components/ui/Attended';
import AgendaItem from '../components/ui/AgendaItem';
import ListActions from '../components/ui/ListActions';

export const dummySiLogData = {
  columns: [
    {
      label: 'SI No.',
      key: 'siNo',
      type: 'base',
    },
    {
      label: 'Revision No.',
      key: 'revisionNo',
      type: 'base',
    },
    {
      label: 'Description',
      key: 'description',
      type: 'base',
    },
    {
      label: 'Date Recieved from Consultant',
      key: 'dateRecievedConsultant',
      type: 'date',
    },
    {
      label: 'Date Submitted to Trades',
      key: 'dateSubmittedTrades',
      type: 'date',
    },
    {
      label: 'Selected Trades Sent to',
      key: 'selectedTradesSentTo',
      type: 'base',
    },
    {
      label: 'Date Required from Trades',
      key: 'dateRequiredTrades',
      type: 'date',
    },
    {
      label: 'Date Received from Trades',
      key: 'dateReceivedTrades',
      type: 'date',
    },
    {
      label: 'Date Submitted to Consultant',
      key: 'dateSubmittedConsultant',
      type: 'date',
    },
    {
      label: 'Date Required from Consultant',
      key: 'dateRequiredConsultant',
      type: 'date',
    },
    {
      label: 'Date Returned from Consultant',
      key: 'dateReturnedConsultant',
      type: 'date',
    },
    {
      label: 'CCN/CCO/CD No.',
      key: 'ccnNo',
      type: 'base',
    },
    {
      label: 'Cost Impact',
      key: 'costImpact',
      type: 'base',
    },
    {
      label: 'Status',
      key: 'status',
      type: 'base',
    },
  ],
  rows: [
    {
      siNo: 1,
      revisionNo: 0,
      description: '001',
      dateRecievedConsultant: 0,
      dateSubmittedTrades: 'Box Culvert Excavation',
      selectedTradesSentTo: 'Nightengale Construction',
      dateRequiredTrades: 'Yellowridge Construction Ltd.',
      dateReceivedTrades: 'LaFarge Canada Inc.',
      dateSubmittedConsultant: '01-06-2020',
    },
    {
      id: 2,
      attachments: 1,
      rfiNo: '001',
      revisionNo: 1,
      description: 'Box Culvert Excavation and Exploration',
      submittedTo: 'Nightengale Construction',
      submittedBy: 'Yellowridge Construction Ltd.',
      preparedBy: 'LaFarge Canada Inc.',
      propertyValues: {
        request: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
            when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
        dateSubmitted: '2020-02-01T19:19:28.475Z',
        dateRequired: '',
        dateReturned: '',
        status: 'Open',
      },
    },
    {
      id: 3,
      attachments: 0,
      rfiNo: '002',
      revisionNo: 0,
      description: 'Crane Assembly',
      submittedTo: 'Ledcor Construction',
      submittedBy: 'Yellowridge Construction Ltd.',
      preparedBy: 'Acme Construction Inc.',
      propertyValues: {
        request: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
            when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
        dateSubmitted: '2020-04-02T19:19:28.475Z',
        dateRequired: '2020-05-01T19:19:28.475Z',
        dateReturned: '',
        status: 'Open',
      },
    },
  ],
};

export const projectList = [
  {
    code: 'development-project',
    company_id: 1,
    date_completed: null,
    date_started: '2020-02-01',
    id: 1,
    name: 'Development Project',
    status: 1,
  },
  {
    code: 'ui-ux-project',
    company_id: 1,
    date_completed: null,
    date_started: '2020-02-01',
    id: 2,
    name: 'UI/UX Project',
    status: 1,
  },
  {
    code: 'front-end-project',
    company_id: 1,
    date_completed: null,
    date_started: '2020-02-01',
    id: 3,
    name: 'Front-end Project',
    status: 1,
  },
  {
    code: 'node-js-project',
    company_id: 1,
    date_completed: null,
    date_started: '2020-02-01',
    id: 4,
    name: 'Node JS Project',
    status: 1,
  },
];

export const meeting = {
  id: 3,
  group_id: 1,
  name: 'UX/UI Planning Meeting',
  meeting_number: 3,
  minutes_sent: 0,
  review_sent: 0,
  start_time: '2021-01-18T22:00:00.000-08:00',
  end_time: '2021-01-18T22:30:00.000-08:00',
  location: 'Meeting Room 1',
  next_meeting_id: 4,
  previous_meeting_id: 2,
  last_meeting_id: 3, // make the mock meeting editable
};

export const meetingList = [
  {
    id: 3,
    groupId: 1,
    name: 'UX/UI Planning Meeting',
    meetingNumber: 3,
    minutesSent: 0,
    reviewSent: 0,
    startTime: '2020-01-17T22:00:00.000-08:00',
    endTime: '2020-01-17T22:30:00.000-08:00',
    location: 'Meeting Room 1',
  },
  {
    id: 2,
    groupId: 1,
    name: 'UX/UI Planning Meeting',
    meetingNumber: 2,
    minutesSent: 1,
    reviewSent: 1,
    startTime: '2020-01-16T22:00:00.000-08:00',
    endTime: '2020-01-16T22:30:00.000-08:00',
    location: 'Meeting Room 1',
  },
  {
    id: 1,
    groupId: 1,
    name: 'UX/UI Planning Meeting',
    meetingNumber: 1,
    minutesSent: 1,
    reviewSent: 1,
    startTime: '2020-01-15T22:00:00.000-08:00',
    endTime: '2020-01-15T22:30:00.000-08:00',
    location: 'Meeting Room 1',
  },
];

export const meetingListStatus = [
  {
    id: 1,
    meetingTitle: 'UX/UI Planning Meeting',
    lastMeetingAt: '2020-09-14T09:30-0700',
    mainCount: 12,
    summary: [
      {
        id: 1,
        low: 4,
        medium: 6,
        high: 2,
      },
    ],
    pathname: '/meetingseries/seriesone',
    overdueCount: 3,
    completedCount: 8,
  },
  {
    id: 2,
    meetingTitle: 'UX/UI Planning Meeting',
    lastMeetingAt: '2020-09-14T09:30-0700',
    pathname: '/meetingseries/seriesone',
    mainCount: 14,
    summary: [
      {
        id: 2,
        low: 4,
        medium: 7,
        high: 3,
      },
    ],
    overdueCount: 2,
    completedCount: 5,
  },
  {
    id: '3',
    meetingTitle: 'UX/UI Planning Meeting',
    datetime: '2020-03-18T09:30-0700',
    lastMeetingAt: '2020-09-14T09:30-0700',
    pathname: '/meetingseries/seriesone',
    summary: [
      {
        id: 3,
        low: 5,
        medium: 3,
        high: 2,
      },
    ],
    mainCount: 10,
    overdueCount: 5,
    completedCount: 5,
  },
];

export const upcomingMeetingList = meetingList.slice(0, 3);

export const userlist = [
  {
    value: 'gregory@redwerks.org',
    label: 'gregory@redwerks.org',
  },
  {
    value: 'fraiser@tazo.com',
    label: 'fraiser@tazo.com',
  },
  {
    value: 'joey@zarny.com',
    label: 'joey@zarny.com',
  },
  {
    value: 'duke@gmail.com',
    label: 'duke@gmail.com',
  },
];

export const seriesList = [
  {
    value: 'Select a Meeting Series',
    label: 'Select a Meeting Series',
  },
  {
    value: 'UI/UX Development Meeting',
    label: 'UI/UX Development Meeting',
  },
  {
    value: 'GMD Library Meeting',
    label: 'GMD Library Meeting',
  },
  {
    value: 'Frontend Development Meeting',
    label: 'Frontend Development Meeting',
  },
];

export const userListExtended = [
  {
    avatar: 'A',
    primary: 'Gregory Thomas',
    secondary: 'Redwerks',
    email: 'gregory@redwerks.org',
  },
  {
    avatar: 'B',
    primary: 'Gregory Thomas',
    secondary: 'Redwerks',
    email: 'gregory@redwerks.org',
  },
  {
    avatar: 'C',
    primary: 'Gregory Thomas',
    secondary: 'Redwerks',
    email: 'gregory@redwerks.org',
  },
  {
    avatar: 'D',
    primary: 'Gregory Thomas',
    secondary: 'Redwerks',
    email: 'gregory@redwerks.org',
  },
  {
    avatar: 'E',
    primary: 'Gregory Thomas',
    secondary: 'Redwerks',
    email: 'gregory@redwerks.org',
  },
];

export function createData(
  columnOne,
  columnTwo,
  columnThree,
  columnFour,
  columnFive,
  columnSix,
  columnSeven
) {
  return {
    columnOne,
    columnTwo,
    columnThree,
    columnFour,
    columnFive,
    columnSix,
    columnSeven,
  };
}

export const rows = [
  createData(
    <Attended status="positive" name="Gregory Thomas" />,
    'Redwerks',
    <StatusIndicator status="neutral" />,
    <StatusIndicator status="positive" />,
    null,
    <ListActions type="editDelete" />
  ),
  createData(
    <Attended status="neutral" name="Robert Morrison" />,
    'Paceology Optimization',
    <StatusIndicator status="neutral" />,
    <StatusIndicator status="positive" />,
    null,
    <ListActions type="editDelete" />
  ),
  createData(
    <Attended status="positive" name="Daniel Friesen" />,
    'Redwerks Systems',
    <StatusIndicator status="neutral" />,
    <StatusIndicator status="positive" />,
    <StatusIndicator status="positive" />,
    <ListActions type="editDelete" />
  ),
  createData(
    <Attended status="positive" name="Brad Atchison" />,
    'Univerus ',
    <StatusIndicator status="neutral" />,
    <StatusIndicator status="positive" />,
    null,
    <ListActions type="editDelete" />
  ),
  createData(
    <Attended
      status="positive"
      name="
    Mike Christie"
    />,
    'Univerus',
    <StatusIndicator status="neutral" />,
    <StatusIndicator status="positive" />,
    null,
    <ListActions type="editDelete" />
  ),
  createData(
    <Attended status="positive" name="Gregory Thomas" />,
    'Redwerks',
    <StatusIndicator status="positive" />,
    <StatusIndicator status="positive" />,
    null,
    <ListActions type="editDelete" />
  ),
];

export const headCells = [
  { id: 'columnOne', numeric: false, disablePadding: true, label: 'Attended' },
  { id: 'columnTwo', numeric: true, disablePadding: false, label: 'Company' },
  {
    id: 'columnThree',
    numeric: true,
    disablePadding: false,
    label: 'Send Review',
  },
  {
    id: 'columnFour',
    numeric: true,
    disablePadding: false,
    label: 'Send Minutes',
  },
  {
    id: 'columnFive',
    numeric: true,
    disablePadding: false,
    label: 'Minutes Taker',
  },
  {
    id: 'columnSix',
    numeric: true,
    disablePadding: false,
    label: 'Actions',
  },
];

export const seriesHeader = [
  {
    id: 'columnOne',
    numeric: false,
    disablePadding: true,
    label: 'Type',
  },
  { id: 'columnTwo', numeric: false, disablePadding: true, label: 'Category' },
  { id: 'columnThree', numeric: true, disablePadding: false, label: '#' },
  {
    id: 'columnFour',
    numeric: true,
    disablePadding: false,
    label: 'Item',
  },
  {
    id: 'columnFive',
    numeric: true,
    disablePadding: false,
    label: 'Due Date',
  },
  {
    id: 'columnSix',
    numeric: true,
    disablePadding: false,
    label: 'Owner',
  },
  {
    id: 'columnSeven',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'columnEight',
    numeric: true,
    disablePadding: false,
    label: 'Priority',
  },
  {
    id: 'columnNine',
    numeric: true,
    disablePadding: false,
    label: 'Actions',
  },
];

export const seriesMinutes = [
  createData(
    <StatusIndicator status="positive" />,
    '1.1',

    <AgendaItem
      agendaItem="Update Commission report to include description - commission bsed on Imp, first year of annual fee and %"
      agendaDesc=""
      agendaDate="January 31, 2020"
    />,

    'January 31, 2020',
    'Gregory Thomas',
    <StatusIndicator status="positive" />,
    <PriorityIndicator priority="low" />
  ),
  createData(
    <StatusIndicator status="neutral" />,
    '1.2',
    <AgendaItem
      agendaItem="Summarize initial customer feedback for next meeting"
      agendaDesc=""
      agendaDate="February 2, 2020"
    />,
    'February 2, 2020',
    'Lary David',
    <StatusIndicator status="positive" />,
    <PriorityIndicator priority="medium" />
  ),
  createData(
    <StatusIndicator status="negative" />,
    '1.3',
    <AgendaItem
      agendaItem="Process for Reviewing RFP."
      agendaDesc="Rick to work with Sales to develop a process for reviewing RFP's"
      agendaDate="February 9, 2020"
    />,

    'February 9, 2020',
    'John Smith',
    <StatusIndicator status="positive" />,
    <PriorityIndicator priority="high" />
  ),
  createData(
    <StatusIndicator status="negative" />,
    '1.4',
    <AgendaItem
      agendaItem="Implementation Plan"
      agendaDesc="Vini to send out sample implementation plan to Board. Figure out and map implementation cost (houts against an average client site full implementation)"
      agendaDate="February 16, 2020"
    />,
    'February 16, 2020',
    'Don James',
    null,
    <PriorityIndicator priority="low" />
  ),
  createData(
    <StatusIndicator status="positive" />,
    '1.5',
    <AgendaItem
      agendaItem="Sales Funnel/contacts and leads"
      agendaDesc="Sales funnel is missing from report. Rick to work with Terry to extract data and ask Matthew to add. This includes all sales contacts, and sales leads to date. 1. Create Lead. 2. Qualify. 3. Develop. 4. Proposal. 5. Negotiate. 6. Close Matthew to also add job description for sales lead with measures report."
      agendaDate="february 28, 2020"
    />,
    'February 28, 2020',
    'Joey Canyon',
    null,
    <PriorityIndicator priority="high" />
  ),
  createData(
    <StatusIndicator status="positive" />,
    '1.6',
    <AgendaItem
      agendaItem="Determining primary and secondary app colors."
      agendaDesc="These colors will be part of the MUI theme"
      agendaDate="March 1, 2020"
    />,
    'March 1, 2020',
    'Meadow Jones',
    null,
    <PriorityIndicator priority="high" />
  ),
];

export const meetingGroups = [
  {
    id: 1,
    name: 'Front-end development meeting',
    meetingNumber: 1,
    openItems: 4,
    startTime: '2020-01-15T22:00:00-08:00',
    minutesSent: 'Yes',
    reviewSent: 'No',
    attendees: 'Gregory Thomas, Tom Jones, Jason Times',
  },
  {
    id: 2,
    name: 'UI / UX Design meeting',
    meetingNumber: 4,
    openItems: 7,
    startTime: '2020-01-21T22:00:00-08:00',
    minutesSent: 'Yes',
    reviewSent: 'No',
    attendees:
      'Martin Mahoney, Jane Leeves, Gregory Thomas, Tom Jones, Jason Times',
  },
  {
    id: 3,
    name: 'Front-end development meeting',
    meetingNumber: 10,
    openItems: 4,
    startTime: '2020-01-15T22:00:00-08:00',
    minutesSent: 'No',
    reviewSent: 'No',
    attendees: 'Gregory Thomas, Tom Jones, Jason Times',
  },
  {
    id: 4,
    name: 'UI / UX Design meeting',
    meetingNumber: 6,
    openItems: 7,
    startTime: '2020-01-21T22:00:00-08:00',
    minutesSent: 'No',
    reviewSent: 'Yes',
    attendees:
      'Martin Mahoney, Jane Leeves, Gregory Thomas, Tom Jones, Jason Times',
  },
  {
    id: 5,
    name: 'Front-end development meeting',
    meetingNumber: 2,
    openItems: 4,
    startTime: '2020-01-15T22:00:00-08:00',
    minutesSent: 'Yes',
    reviewSent: 'Yes',
    attendees: 'Gregory Thomas, Tom Jones, Jason Times',
  },
  {
    id: 6,
    name: 'UI / UX Design meeting',
    meetingNumber: 13,
    openItems: 7,
    startTime: '2020-01-21T22:00:00-08:00',
    minutesSent: 'No',
    reviewSent: 'No',
    attendees:
      'Martin Mahoney, Jane Leeves, Gregory Thomas, Tom Jones, Jason Times',
  },
];

// DON'T CHANGE, using in <NewMeetingItem>
export const statusList = [
  {
    value: 0,
    label: 'Open',
  },
  {
    value: 1,
    label: 'Closed',
  },
  {
    value: 2,
    label: 'Info',
  },
];
// DON'T CHANGE, using in <NewMeetingItem>
export const priorityList = [
  {
    value: 'None',
    label: 'None',
  },
  {
    value: 'Low',
    label: 'Low',
  },
  {
    value: 'Medium',
    label: 'Medium',
  },
  {
    value: 'High',
    label: 'High',
  },
  {
    value: 'Critical',
    label: 'Critical',
  },
];

export const categoryList = [
  {
    value: 'main',
    label: 'Main',
  },
  {
    value: 'submissions',
    label: 'Submissions',
  },
];

// Submittals
export const dummySubmittalTypes = [
  { id: 1, key: 'tradeContract', name: 'Trade Contract' },
  {
    id: 2,
    key: 'shopDrawings',
    name: 'Shop Drawings',
    propertyMap: [{ label: 'startTime Received', field: 'dateReceived' }],
  },
  {
    id: 3,
    key: 'samples',
    name: 'Samples',
    propertyMap: [{ label: 'Date Completed', field: 'dateCompleted' }],
  },
  { id: 4, key: 'engineerSchedules', name: 'Engineer Schedules' },
  {
    id: 5,
    key: 'leed',
    name: 'LEED',
    propertyMap: [{ label: 'Date Received', field: 'dateReceived' }],
  },
  {
    id: 6,
    key: 'operationsMaintenanceManuals',
    name: 'Operations & Maintenance Manuals',
  },
  { id: 7, key: 'warranty', name: 'Warranty' },
  { id: 8, key: 'maintenanceMaterials', name: 'Maintenance Materials' },
  { id: 9, key: 'mockUps', name: 'Mock-Ups' },
  { id: 10, key: 'testing', name: 'Testing' },
  { id: 11, key: 'demonstrationsTraining', name: 'Demonstrations & Training' },
  {
    id: 12,
    key: 'engineersSeal',
    name: 'Engineers Seal',
    propertyMap: [{ label: 'Date Completed', field: 'dateCompleted' }],
  },
  // Properties Test
  {
    id: 13,
    key: 'test',
    name: 'Properties Test',
  },
];

export const dummySpecifications = [
  { code: 10000, name: 'General Construction', id: 0 },
  { code: 10020, name: 'Permit needed for adding toilets', id: 1 },
  { code: 11034, name: 'Added roofing', id: 2 },
  { code: 12000, name: 'Bonds', id: 3 },
  { code: 12010, name: 'Insurance', id: 4 },
  { code: 12020, name: 'Legal Fees', id: 5 },
  { code: 12030, name: 'Building Permit', id: 6 },
  { code: 12040, name: 'Business License', id: 7 },
  { code: 12100, name: 'Cash Allowances', id: 8 },
  { code: 12110, name: 'Construction Contingency', id: 9 },
  { code: 12119, name: 'Testing and Inspecting Allowances', id: 10 },
  { code: 13100, name: 'Project Manager', id: 11 },
  { code: 13102, name: 'Site Visits', id: 12 },
  { code: 13110, name: 'Project Coordinator', id: 13 },
  { code: 13120, name: 'LEED Coordinator', id: 14 },
  { code: 13000, name: 'Administrative Requirements', id: 15 },
  { code: 13150, name: 'Superintendent', id: 16 },
  { code: 13152, name: 'Superintendent Move In/Travel', id: 17 },
  { code: 13154, name: 'Superintendent Living Expense', id: 18 },
  { code: 13156, name: 'Superintendent Pickup Truck', id: 19 },
  { code: 13160, name: 'Foreman', id: 20 },
  { code: 13170, name: 'First Aid & Safety Officer', id: 21 },
  { code: 13190, name: 'Room & Board', id: 22 },
  { code: 13200, name: 'Legal Survey', id: 23 },
  { code: 13210, name: 'Site & Building Layout', id: 24 },
  { code: 13249, name: 'Piping required to reroute water', id: 25 },
  { code: 13300, name: 'Drawings & Submittals', id: 26 },
  { code: 14500, name: 'Concrete Testing', id: 27 },
  { code: 14510, name: 'Soil Testing', id: 28 },
  { code: 14520, name: 'Asphalt Testing', id: 29 },
  { code: 14530, name: 'Steel Testing', id: 30 },
  { code: 14540, name: 'Window Testing', id: 31 },
  { code: 14545, name: 'Blower Door Testing', id: 32 },
  { code: 15100, name: 'Temporary Electricity', id: 33 },
  { code: 15110, name: 'Temporary Lighting', id: 34 },
  { code: 15120, name: 'Temporary Heating, Cooling & Ventilating', id: 35 },
  { code: 15130, name: 'Temporary Telecommunications', id: 36 },
  { code: 15140, name: 'Temporary Water', id: 37 },
  { code: 15150, name: 'Temporary Sanitary Facilities', id: 38 },
  { code: 15200, name: 'Field Office & Sheds', id: 39 },
  { code: 15210, name: 'Field Office Supplies', id: 40 },
  { code: 15220, name: 'Miscellaneous Cartage', id: 41 },
  { code: 15230, name: 'First Aid Supplies', id: 42 },
  { code: 15250, name: 'Site Security', id: 43 },
  { code: 15270, name: 'Small Tools & Repairs', id: 44 },
  { code: 15410, name: 'Temporary Forklifts', id: 45 },
  { code: 15420, name: 'Temporary Cranes', id: 46 },
  { code: 15430, name: 'Temporary Scaffolding & Platforms', id: 47 },
  { code: 15510, name: 'Temporary Access Roads', id: 48 },
  { code: 15520, name: 'Traffic Control', id: 49 },
  { code: 15600, name: 'Temporary Fencing', id: 50 },
  { code: 15610, name: 'Temporary Tree & Plant Protection', id: 51 },
  { code: 15620, name: 'Temporary Winter & Rain Enclosures', id: 52 },
  { code: 15630, name: 'Temporary Ladders & Guard Rails', id: 53 },
  { code: 15640, name: 'Temporary Dust Barriers', id: 54 },
  { code: 15650, name: 'Temporary Infection Control', id: 55 },
  { code: 15710, name: 'Pumping & Dewatering', id: 56 },
  { code: 15720, name: 'Snow Removal', id: 57 },
  { code: 15810, name: 'Temporary Project Signage', id: 58 },
  { code: 17400, name: 'Progress Cleaning & Disposal', id: 59 },
  { code: 17450, name: 'Final Cleaning', id: 60 },
  { code: 17700, name: 'Deficiencies', id: 61 },
  { code: 17710, name: 'Commissioning', id: 62 },
  { code: 17720, name: 'Demonstrations', id: 63 },
  { code: 17800, name: 'Maintenance Manuals', id: 64 },
  { code: 17810, name: 'New Home Warranty', id: 65 },
  { code: 17820, name: 'Warranties', id: 66 },
  { code: 20000, name: 'EXISTING CONDITIONS', id: 67 },
  { code: 23547, name: 'Project Manager to oversee contruction', id: 68 },
  { code: 24100, name: 'Demolition', id: 69 },
  { code: 24119, name: 'Selective Demolition', id: 70 },
  { code: 28200, name: 'Asbestos Remediation', id: 71 },
  { code: 28000, name: 'Facility Remediation', id: 72 },
  { code: 30000, name: 'CONCRETE', id: 73 },
  { code: 31010, name: 'Concrete Forming Structural', id: 74 },
  { code: 31020, name: 'Concrete Forming SOG & Toppings', id: 75 },
  { code: 31030, name: 'Concrete Forming Miscellaneous Interior', id: 76 },
  { code: 31040, name: 'Concrete Forming Miscellaneous Exterior', id: 77 },
  {
    code: 31050,
    name: 'Concrete Forming Exterior Sidewalks, Stairs & Pads',
    id: 78,
  },
  { code: 31500, name: 'Concrete Accessories', id: 79 },
  { code: 31513, name: 'Waterstops', id: 80 },
  { code: 31516, name: 'Concrete Control Joints', id: 81 },
  { code: 32000, name: 'Concrete Reinforcing', id: 82 },
  { code: 33110, name: 'Concrete Supply Structural', id: 83 },
  { code: 33120, name: 'Concrete Supply SOG & Toppings', id: 84 },
  { code: 33130, name: 'Concrete Supply Miscellaneous Interior', id: 85 },
  { code: 33140, name: 'Concrete Supply Miscellaneous Exterior', id: 86 },
  {
    code: 33150,
    name: 'Concrete Supply Exterior Sidewalks, Stairs & Pads',
    id: 87,
  },
  { code: 33510, name: 'Concrete Finish Structural', id: 88 },
  { code: 33520, name: 'Concrete Finish SOG & Toppings', id: 89 },
  { code: 33530, name: 'Concrete Finish Miscellaneous Interior', id: 90 },
  { code: 33540, name: 'Concrete Finish Miscellaneous Exterior', id: 91 },
  {
    code: 33550,
    name: 'Concrete Finish Exterior Sidewalks, Stairs & Pads',
    id: 92,
  },
  { code: 33590, name: 'Sandblasting', id: 93 },
  { code: 33713, name: 'Shotcrete', id: 94 },
  { code: 33716, name: 'Concrete Pumping', id: 95 },
  { code: 34100, name: 'Precast Structural Concrete', id: 96 },
  { code: 34500, name: 'Precast Architectural Concrete', id: 97 },
  { code: 34713, name: 'Tilt-up Concrete', id: 98 },
  { code: 35413, name: 'Gypsum Cement Underlayment', id: 99 },
  { code: 36200, name: 'Non-Shrink Grout', id: 100 },
  { code: 38100, name: 'Concrete Cutting', id: 101 },
  { code: 38200, name: 'Concrete Boring', id: 102 },
  { code: 40000, name: 'MASONRY', id: 103 },
  { code: 42000, name: 'Unit Masonry', id: 104 },
  { code: 44000, name: 'Stone Assemblies', id: 105 },
  { code: 50000, name: 'METALS', id: 106 },
  { code: 51000, name: 'Structural Metal Framing', id: 107 },
  { code: 52000, name: 'Metal Joists', id: 108 },
  { code: 53000, name: 'Metal Decking', id: 109 },
  { code: 55000, name: 'Metal Fabrications', id: 110 },
  { code: 55100, name: 'Metal Stairs', id: 111 },
  { code: 55200, name: 'Steel Railings', id: 112 },
  { code: 55500, name: 'Metal Stair Treads & Nosings', id: 113 },
  { code: 57300, name: 'Aluminum Railings', id: 114 },
  { code: 57500, name: 'Stainless Steel Fabrications', id: 115 },
  { code: 60000, name: 'WOOD, PLASTICS & COMPOSITES', id: 116 },
  { code: 61000, name: 'Miscellaneous Rough Carpentry', id: 117 },
  { code: 61100, name: 'Wood Framing', id: 118 },
  { code: 61110, name: 'Hadrware & Accessories', id: 119 },
  { code: 61113, name: 'Engineered Wood Products', id: 120 },
  { code: 61300, name: 'Heavy Timber Construction', id: 121 },
  { code: 61626, name: 'Underlayment', id: 122 },
  { code: 61710, name: 'Shop-Fabricated Wall Panels', id: 123 },
  { code: 61719, name: 'Cross Laminated Timber', id: 124 },
  { code: 61753, name: 'Shop-Fabricated Wood Trusses', id: 125 },
  { code: 61800, name: 'Glued-Laminated Construction', id: 126 },
  { code: 62000, name: 'Finish Carpentry', id: 127 },
  { code: 64000, name: 'Architectural Woodwork', id: 128 },
  { code: 64150, name: 'Countertops', id: 129 },
  { code: 64600, name: 'Wood Trim', id: 130 },
  { code: 70000, name: 'THERMAL & MOISTURE PROTECTION', id: 131 },
  { code: 71100, name: 'Dampproofing', id: 132 },
  { code: 71300, name: 'Sheet Waterproofing', id: 133 },
  { code: 71354, name: 'Sheet Vinyl Waterproofing', id: 134 },
  { code: 71400, name: 'Fluid Applied Waterproofing', id: 135 },
  { code: 71813, name: 'Pedestrian Traffic Coatings', id: 136 },
  { code: 71816, name: 'Vehicular Traffic Coatings', id: 137 },
  { code: 72113, name: 'Board Insulation', id: 138 },
  { code: 72116, name: 'Blanket Insulation', id: 139 },
  { code: 72119, name: 'Sprayed Insulation', id: 140 },
  { code: 72616, name: 'Vapour Retarder u/s SOG', id: 141 },
  { code: 72713, name: 'Modified Bituminous Sheet Air Barriers', id: 142 },
  { code: 73100, name: 'Shingles & Shakes', id: 143 },
  { code: 74100, name: 'Metal Roof Panel', id: 144 },
  { code: 74213, name: 'Metal Wall Panels', id: 145 },
  { code: 74293, name: 'Soffit Panels', id: 146 },
  { code: 74619, name: 'Steel Siding', id: 147 },
  { code: 74623, name: 'Wood Siding', id: 148 },
  { code: 74633, name: 'Vinyl Siding', id: 149 },
  { code: 74646, name: 'Fiber Cement Siding', id: 150 },
  { code: 75000, name: 'Membrane Roofing', id: 151 },
  { code: 76000, name: 'Sheet Metal Flashing & Trim', id: 152 },
  { code: 76100, name: 'Sheet Metal Roofing', id: 153 },
  { code: 77123, name: 'Gutters & Downspouts', id: 154 },
  { code: 77233, name: 'Roof Hatches', id: 155 },
  { code: 78100, name: 'Applied Fireproofing', id: 156 },
  { code: 78400, name: 'Firestopping', id: 157 },
  { code: 79200, name: 'Joint Sealants', id: 158 },
  { code: 79500, name: 'Expansion Control', id: 159 },
  { code: 80000, name: 'OPENINGS', id: 160 },
  { code: 81100, name: 'Metal Doors & Frames', id: 161 },
  { code: 81400, name: 'Wood Doors', id: 162 },
  { code: 83000, name: 'Specialty Doors', id: 163 },
  { code: 83100, name: 'Access Doors & Panels', id: 164 },
  { code: 83300, name: 'Coling Doors & Grilles', id: 165 },
  { code: 83436, name: 'Darkroom Doors', id: 166 },
  { code: 83463, name: 'Detention Doors & Frames', id: 167 },
  { code: 83500, name: 'Folding Doors & Grilles', id: 168 },
  { code: 83600, name: 'Panel Doors', id: 169 },
  {
    code: 84000,
    name: 'Aluminum Entrances, Storefront & Curtain Wall',
    id: 170,
  },
  { code: 85100, name: 'Aluminum Windows', id: 171 },
  { code: 85300, name: 'Vinyl Windows', id: 172 },
  { code: 86200, name: 'Unit Skylights', id: 173 },
  { code: 87100, name: 'Door Hardware', id: 174 },
  { code: 87113, name: 'Automatic Door Operators', id: 175 },
  { code: 87400, name: 'Access Control Hardware', id: 176 },
  { code: 88100, name: 'Glass Glazing', id: 177 },
  { code: 88300, name: 'Mirrors', id: 178 },
  { code: 89000, name: 'Louvres and vents', id: 179 },
  { code: 90000, name: 'FINISHES', id: 180 },
  { code: 92116, name: 'Gypsum Board Assemblies', id: 181 },
  { code: 92216, name: 'Non-Structural Metal Framing', id: 182 },
  { code: 92423, name: 'Cement Stucco', id: 183 },
  { code: 93000, name: 'Tiling', id: 184 },
  { code: 95113, name: 'Acoustical Panel Ceilings', id: 185 },
  { code: 96200, name: 'Specialized Flooring', id: 186 },
  { code: 96400, name: 'Wood Flooring', id: 187 },
  { code: 96500, name: 'Resilient Flooring', id: 188 },
  { code: 96700, name: 'Fluid-Applied Flooring', id: 189 },
  { code: 96800, name: 'Carpeting', id: 190 },
  { code: 96900, name: 'Access Flooring', id: 191 },
  { code: 98000, name: 'Acoustic Treatment', id: 192 },
  { code: 99100, name: 'Painting & Coating', id: 193 },
  { code: 99400, name: 'Wall Coverings', id: 194 },
  { code: 99600, name: 'Performance Coatings', id: 195 },
  { code: 99656, name: 'Epoxy Coatings', id: 196 },
  { code: 99700, name: 'Special Coatings', id: 197 },
  { code: 100000, name: 'SPECIALTIES', id: 198 },
  { code: 101100, name: 'Visual Display Units', id: 199 },
  { code: 101400, name: 'Signage', id: 200 },
  { code: 102113, name: 'Toilet Compartments', id: 201 },
  { code: 102123, name: 'Cubicle Curtains & Tracks', id: 202 },
  { code: 102239, name: 'Folding Panel Partitions', id: 203 },
  { code: 102613, name: 'Corner Guards', id: 204 },
  { code: 102623, name: 'Protective Wall Covering', id: 205 },
  { code: 102800, name: 'Toilet, Bath & Laundry Accessories', id: 206 },
  { code: 102850, name: 'Hand Dryers', id: 207 },
  { code: 103000, name: 'Fireplaces', id: 208 },
  { code: 104416, name: 'Fire Extinguishers', id: 209 },
  { code: 104500, name: 'Fire Safety Plan', id: 210 },
  { code: 105113, name: 'Metal Lockers', id: 211 },
  { code: 105523, name: 'Mail Boxes', id: 212 },
  { code: 105613, name: 'Metal Storage Shelving', id: 213 },
  { code: 105723, name: 'Closet & Utility Shelving', id: 214 },
  { code: 107112, name: 'Exterior Sun Control Devices', id: 215 },
  { code: 107113, name: 'Exterior Shutters', id: 216 },
  { code: 107114, name: 'Fire Shutters', id: 217 },
  { code: 107500, name: 'Flagpoles', id: 218 },
  { code: 110000, name: 'EQUIPMENT', id: 219 },
  { code: 110100, name: 'Owner Supplied Equipment', id: 220 },
  { code: 111300, name: 'Loading Dock Equipment', id: 221 },
  { code: 112173, name: 'Commercial Laundry Equipment', id: 222 },
  { code: 113013, name: 'Residential Appliances', id: 223 },
  { code: 113033, name: 'Retractable Stairs', id: 224 },
  { code: 114000, name: 'Food Service Equipment', id: 225 },
  { code: 115213, name: 'Projection Screens', id: 226 },
  { code: 116100, name: 'Broadcast, Theater, and Stage Equipment', id: 227 },
  { code: 116623, name: 'Gymnasium Equipment', id: 228 },
  { code: 116813, name: 'Playground Equipment', id: 229 },
  { code: 117000, name: 'Healthcare Equipment', id: 230 },
  { code: 117300, name: 'Patient Care Equipment', id: 231 },
  { code: 117400, name: 'Heat Treatment Equipment', id: 232 },
  { code: 118129, name: 'Fall Safety Arrest', id: 233 },
  { code: 118226, name: 'Facility Waste Compactors', id: 234 },
  { code: 120000, name: 'FURNISHINGS', id: 235 },
  { code: 122000, name: 'Window Treatments', id: 236 },
  { code: 123000, name: 'Casework', id: 237 },
  { code: 124813, name: 'Entrance Floor Mats', id: 238 },
  { code: 124816, name: 'Entrance Floor Grilles', id: 239 },
  { code: 125219, name: 'Upholstered Seating', id: 240 },
  { code: 125600, name: 'Institutional Furniture', id: 241 },
  { code: 125713, name: 'Welding Benches', id: 242 },
  { code: 126613, name: 'Telescoping Bleachers', id: 243 },
  { code: 126623, name: 'Telescoping Seating', id: 244 },
  { code: 130000, name: 'SPECIAL CONSTRUCTION', id: 245 },
  { code: 131813, name: 'Ice Rink Floor Systems', id: 246 },
  { code: 131816, name: 'Ice Rink Dasher Boards', id: 247 },
  { code: 133400, name: 'Fabricated Engineered Structures', id: 248 },
  { code: 140000, name: 'CONVEYING EQUIPMENT', id: 249 },
  { code: 142000, name: 'Elevators', id: 250 },
  { code: 144200, name: 'Wheelchair Lifts', id: 251 },
  { code: 200000, name: 'FEES & CONTINGENCIES', id: 252 },
  { code: 201000, name: 'Construction Management Fee', id: 253 },
  { code: 201500, name: 'Construction Management Reimbe', id: 254 },
  { code: 202050, name: 'Architectural Consultant', id: 255 },
  { code: 202100, name: 'Structural Consultant', id: 256 },
  { code: 202150, name: 'Mechanical Consultant', id: 257 },
  { code: 202160, name: 'Commissioning Consultant', id: 258 },
  { code: 202170, name: 'LEED Consultant', id: 259 },
  { code: 202200, name: 'Electrical Consultant', id: 260 },
  { code: 202250, name: 'Civil Consultant', id: 261 },
  { code: 202260, name: 'Geotechnical Consultant', id: 262 },
  { code: 202300, name: 'Landscape Consultant', id: 263 },
  { code: 202310, name: 'Arborist', id: 264 },
  { code: 202320, name: 'Street and Pedestrian Lighting Consultant', id: 265 },
  { code: 202350, name: 'Building Envelope Consultant', id: 266 },
  { code: 202400, name: 'Environmental Consultant', id: 267 },
  { code: 202450, name: 'Cost Consultant', id: 268 },
  { code: 202500, name: 'Traffic Consultant', id: 269 },
  { code: 202550, name: 'Code Consultant', id: 270 },
  { code: 202600, name: 'Acoustic Consultant', id: 271 },
  { code: 202900, name: 'LEED Registration', id: 272 },
  { code: 202950, name: 'Consultant Disbursements', id: 273 },
  { code: 210000, name: 'FIRE SUPPRESSION', id: 274 },
  { code: 211000, name: 'Fire Suppression', id: 275 },
  { code: 220000, name: 'PLUMBING', id: 276 },
  { code: 221000, name: 'Plumbing', id: 277 },
  { code: 230000, name: 'HEATING, VENTILATING & AIR CONDITIONING', id: 278 },
  { code: 231000, name: 'Heating, Venting & Air  Conditioning', id: 279 },
  { code: 260000, name: 'ELECTRICAL', id: 280 },
  { code: 261000, name: 'Electrical', id: 281 },
  { code: 270000, name: 'COMMUNICATIONS', id: 282 },
  { code: 271000, name: 'Communications', id: 283 },
  { code: 280000, name: 'ELECTRONIC SAFETY & SECURITY', id: 284 },
  { code: 281000, name: 'Electronic Safety & Security', id: 285 },
  { code: 310000, name: 'EARTHWORK', id: 286 },
  { code: 311000, name: 'SITEWORK', id: 287 },
  { code: 311100, name: 'Clearing & Grubbing', id: 288 },
  { code: 312213, name: 'Rough Grading', id: 289 },
  { code: 312219, name: 'Finish Grading', id: 290 },
  { code: 312500, name: 'Erosion & Sediment Control', id: 291 },
  { code: 313200, name: 'Site Preload', id: 292 },
  { code: 313500, name: 'Slope Protection', id: 293 },
  { code: 314000, name: 'Shoring & Underpinning', id: 294 },
  { code: 315100, name: 'Anchor Tiebacks', id: 295 },
  { code: 316200, name: 'Driven Piles', id: 296 },
  { code: 316300, name: 'Bored Piles', id: 297 },
  { code: 320000, name: 'EXTERIOR IMPROVEMENTS', id: 298 },
  { code: 321100, name: 'Base Courses', id: 299 },
  { code: 321216, name: 'Asphalt Paving', id: 300 },
  { code: 321313, name: 'Concrete Paving', id: 301 },
  { code: 321413, name: 'Precast Concrete Unit Paving', id: 302 },
  { code: 321613, name: 'Curbs & Gutters', id: 303 },
  { code: 321713, name: 'Parking Bumpers', id: 304 },
  { code: 321723, name: 'Pavement Markings', id: 305 },
  { code: 321800, name: 'Athletic & Recreational Surfacing', id: 306 },
  { code: 323113, name: 'Chain Link Fences & Gates', id: 307 },
  { code: 323200, name: 'Retaining Walls', id: 308 },
  { code: 323229, name: 'Wood Fences & Gates', id: 309 },
  { code: 323300, name: 'Site Furnishings', id: 310 },
  { code: 323313, name: 'Bike Racks & Bike Lockers', id: 311 },
  { code: 328000, name: 'Irrigation', id: 312 },
  { code: 329000, name: 'Landscaping', id: 313 },
  { code: 330000, name: 'UTILITIES', id: 314 },
  { code: 331000, name: 'Water Utilities', id: 315 },
  { code: 333000, name: 'Sanitary Sewage Utilities', id: 316 },
  { code: 333600, name: 'Septic Systems', id: 317 },
  { code: 334000, name: 'Storm Drainage Utilities', id: 318 },
  { code: 334613, name: 'Perimeter Drains', id: 319 },
  { code: 336000, name: 'Geothermal Fields & Wells', id: 320 },
  { code: 17419, name: 'Waste Management Plan', id: 321 },
];
export const dummyTradeNames = [
  { id: 1, name: 'Ledcor', role: 'vendor' },
  { id: 2, name: 'Acnme Construction', role: 'vendor' },
  { id: 3, name: "Bob's Plumbing", role: 'vendor' },
  { id: 4, name: 'Sheet Metal Co.', role: 'consultant' },
  { id: 5, name: "John's Piping", role: 'consultant' },
  { id: 6, name: 'Nightengale Construction', role: 'consultant' },
];
export const dummyContacts = [
  {
    id: '5',
    name: 'Jane Ledcor',
    email: 'jledcor@mailinator.com',
    role: 'Son',
    isPrimary: '1',
    firstName: 'Jane',
    lastName: 'Ledcor',
    primary_phone_id: 1,
    primary_phone_number: '6041234563',
    primary_extension: '55',
    secondary_phone_id: null,
    secondary_phone_number: null,
    secondary_extension: null,
    companyId: '1',
    companyName: 'Ledcor Consultants',
    companyType: 'consultant',
  },
];
export const dummyProjectContacts = [
  {
    id: '5',
    name: 'Jane Ledcor',
    email: 'jledcor@mailinator.com',
    title: 'Son',
    isPrimary: '1',
    firstName: 'Jane',
    lastName: 'Ledcor',
    primary_phone_id: 1,
    primary_phone_number: '6041234563',
    primary_extension: '55',
    secondary_phone_id: null,
    secondary_phone_number: null,
    secondary_extension: null,
    companyId: '1',
    companyName: 'Ledcor Consultants',
    companyType: 'consultant',
  },
];
export const dummyTradeContacts = [
  {
    id: '5',
    name: 'Bart Simpson',
    email: 'bsimpson@mailinator.com',
    title: 'Son',
    is_primary: '1',
  },
  {
    id: '10',
    name: 'Carter Lee',
    email: 'clee@mailinator.com',
    title: 'Coach',
    is_primary: '0',
  },
];
export const dummyCostCodes = [
  { id: 1, code: 10000, name: 'GENERAL REQUIREMENTS' },
  { id: 5, code: 12030, name: 'Building Permit' },
  { id: 10, code: 15620, name: 'Temporary Water' },
  { id: 35, code: 15140, name: 'Temporary Water' },
  { id: 50, code: 13100, name: 'Project Manager' },
];

export const dummySubmittalPropertiesTable = {
  shopDrawings: [
    {
      type: 'integer',
      key: 'processLeadTime',
      label: 'Process Lead Time',
      unit: 'day',
    },
    {
      type: 'date',
      key: 'dateRequiredFromTrade',
      label: 'Date Required from Trade',
    },
    {
      type: 'date',
      key: 'dateRequestedFromTrade',
      label: 'Date Requested from Trade',
    },
    {
      type: 'integer',
      key: 'productLeadTime',
      label: 'Product Lead Time',
      unit: 'day',
    },
    {
      type: 'date',
      key: 'dateProductRequiredOnSite',
      label: 'Date Product Required on Site',
    },
    {
      type: 'select',
      key: 'levelOfReview',
      label: 'Level of Review',
      options: [
        { value: 1, label: 'Level 1' },
        { value: 2, label: 'Level 2' },
        { value: 3, label: 'Level 3' },
      ],
    },
    {
      type: 'multiline-text',
      key: 'description',
      label: 'Description',
    },
  ],
  samples: [
    {
      type: 'date',
      key: 'dateRequiredFromTrade',
      label: 'Date Required from Trade',
    },
    {
      type: 'date',
      key: 'dateCompleted',
      label: 'Completed',
    },
    {
      type: 'multiline-text',
      key: 'description',
      label: 'Description',
    },
  ],
  test: [
    {
      type: 'integer',
      key: 'integerProperty',
      label: 'Integer Property',
      unit: 'unit',
    },
    {
      type: 'numeric',
      key: 'numericProperty',
      label: 'Numeric Property',
    },
    {
      type: 'date',
      key: 'dateProperty',
      label: 'Date Property',
    },
    {
      type: 'text',
      key: 'textProperty',
      label: 'Text Property',
    },
    {
      type: 'multiline-text',
      key: 'multilineProperty',
      label: 'Multiline Property',
    },
  ],
};

export const dummySubmittalsUsedTypes = dummySubmittalTypes.filter(type =>
  [2, 5, 3, 12].includes(type.id)
);

export const dummySubmittalsRows = [
  {
    specificationNo: 10000,
    specificationDescription: 'General Construction',
    tradeId: 1,
    tradeName: 'Ledcor',
    costCode: 10000,
    costCodeId: 1,
    description: 'GENERAL REQUIREMENTS',
    types: {
      shopDrawings: {
        required: 'Y',
        dateReceived: '09-12-2019',
        submittal_types_id: 2,
      },
      leed: {
        required: 'N',
        dateReceived: 'N/A',
        submittal_types_id: 5,
      },
      samples: {
        required: 'Y',
        dateCompleted: '09-01-2020',
        submittal_types_id: 3,
      },
      engineersSeal: {
        required: 'Y',
        dateReceived: '09-01-2020',
        submittal_types_id: 12,
      },
    },
  },
  {
    specificationNo: 10000,
    specificationDescription: 'General Construction',
    tradeId: 2,
    tradeName: 'Acnme Construction',
    costCode: 10000,
    description: 'GENERAL REQUIREMENTS',
    types: {
      shopDrawings: {
        required: 'Y',
        dateReceived: '09-12-2019',
        submittal_types_id: 2,
      },
      leed: {
        required: 'N',
        dateReceived: 'N/A',
        submittal_types_id: 5,
      },
      samples: {
        required: 'Y',
        dateCompleted: '09-01-2020',
        submittal_types_id: 3,
      },
      engineersSeal: {
        required: 'Y',
        dateReceived: '09-01-2020',
        submittal_types_id: 12,
      },
    },
  },
  {
    specificationNo: 10020,
    specificationDescription: 'Permit needed for adding toilets',
    tradeId: 3,
    tradeName: "Bob's Plumbing",
    costCode: 12030,
    description: 'Building Permit',
    types: {
      shopDrawings: {
        required: 'Y',
        dateReceived: '09-12-2019',
        submittal_types_id: 2,
      },
      leed: {
        required: 'N',
        dateReceived: 'N/A',
        submittal_types_id: 5,
      },
      samples: {
        required: 'Y',
        dateCompleted: '09-01-2020',
        submittal_types_id: 3,
      },
      engineersSeal: {
        required: 'Y',
        dateReceived: '09-01-2020',
        submittal_types_id: 12,
      },
    },
  },
  {
    specificationNo: 11034,
    specificationDescription: 'Added roofing',
    tradeId: 4,
    tradeName: 'Sheet Metal Co.',
    costCode: 15620,
    description: 'Temporary Water & Rain Equipment',
    types: {
      shopDrawings: {
        required: 'Y',
        dateReceived: '09-12-2019',
        submittal_types_id: 2,
      },
      leed: {
        required: 'N',
        dateReceived: 'N/A',
        submittal_types_id: 5,
      },
      samples: {
        required: 'Y',
        dateCompleted: '09-01-2020',
        submittal_types_id: 3,
      },
      engineersSeal: {
        required: 'Y',
        dateReceived: '09-01-2020',
        submittal_types_id: 12,
      },
    },
  },
  {
    specificationNo: 13249,
    specificationDescription: 'Piping required to reroute water',
    tradeId: 5,
    tradeName: "John's Piping",
    costCode: 15140,
    description: 'Temporary Water',
    types: {
      shopDrawings: {
        required: 'Y',
        dateReceived: '09-12-2019',
        submittal_types_id: 2,
      },
      leed: {
        required: 'N',
        dateReceived: 'N/A',
        submittal_types_id: 5,
      },
      samples: {
        required: 'Y',
        dateCompleted: '09-01-2020',
        submittal_types_id: 3,
      },
      engineersSeal: {
        required: 'Y',
        dateReceived: '09-01-2020',
        submittal_types_id: 12,
      },
    },
  },
  {
    specificationNo: 23547,
    specificationDescription: 'Project Manager to oversee contruction',
    tradeId: 6,
    tradeName: 'Nightengale Construction',
    costCode: 13100,
    description: 'Project Manager',
    types: {
      shopDrawings: {
        required: 'Y',
        dateReceived: '09-12-2019',
        submittal_types_id: 2,
      },
      leed: {
        required: 'N',
        dateReceived: 'N/A',
        submittal_types_id: 5,
      },
      samples: {
        required: 'Y',
        dateCompleted: '09-01-2020',
        submittal_types_id: 3,
      },
      engineersSeal: {
        required: 'Y',
        dateReceived: '09-01-2020',
        submittal_types_id: 12,
      },
    },
  },
];

const usedSpecificationNos = new Set(
  dummySubmittalsRows.map(row => row.specificationNo)
);
export const usedDummySpecifications = dummySpecifications.filter(spec =>
  usedSpecificationNos.has(spec.code)
);

export const tradeNamesForSpecification = {};
for (const row of dummySubmittalsRows) {
  tradeNamesForSpecification[row.specificationNo] =
    tradeNamesForSpecification[row.specificationNo] || new Set();
  tradeNamesForSpecification[row.specificationNo].add(row.tradeId);
}

// Submittal logs

export const dummySubmittalLogs = [
  {
    id: 1,
    type: 'shopDrawings',
    specification_no: 10000,
    specification_description: 'General Construction',
    trade_name_id: 1,
    trade_name: 'Ledcor',
  },
  {
    id: 1,
    type: 'samples',
    specification_no: 10000,
    specification_description: 'General Construction',
    trade_name_id: 1,
    trade_name: 'Ledcor',
  },
  {
    id: 2,
    type: 'samples',
    specification_no: 10000,
    specification_description: 'General Construction',
    trade_name_id: 2,
    trade_name: 'Acnme Construction',
  },
];

export const dummySubmittalLog = {
  '1-shopDrawings': [
    {
      yclCostCode: 100,
      yclDescription: 'Blah',
      properties: {
        processLeadTime: 1,
        dateRequiredFromTrade: '...',
        dateRequestedFromTrade: '...',
        productLeadTime: 3,
        dateProductRequiredOnSite: '...',
        levelOfReview: 3,
        description: '...',
      },
    },
    {
      yclCostCode: 100,
      yclDescription: 'Blah',
      properties: {
        processLeadTime: 2,
        dateRequiredFromTrade: '...',
        dateRequestedFromTrade: '...',
        productLeadTime: 1,
        dateProductRequiredOnSite: '...',
        levelOfReview: 1,
        description: '...',
      },
    },
  ].map(item => ({
    ...item,
    specification_no: 10000,
    specification_description: 'General Construction',
    trade_name_id: 1,
    trade_name: 'Ledcor',
  })),
};

export const dummySubmittalsLogEntries = [
  {
    specificationNo: 10000,
    specificationDescription: 'Permit needed for adding toilets',
    tradeName: 'Nightengale Construction',
    systemCode: 13100,
    systemCodeDescription: 'Project Manager',
    submittalsType: 'Trade Contract',
    submittalsTypeKey: 'tradeContract',
    submittalsNo: 1,
    revisionNo: 0,
    received: '2020-03-23T21:19:06.845Z',
    submitted: '2020-03-23',
    reviewed: '2020-03-23T21:19:06.868Z',
    returned: '2020-03-23T21:19:06.887Z',
    status: 'Reviewed',
  },
  {
    specificationNo: 10000,
    specificationDescription: 'Permit needed for adding toilets',
    tradeName: 'Nightengale Construction',
    systemCode: 13100,
    systemCodeDescription: 'Project Manager',
    submittalsType: 'Shop Drawings',
    submittalsTypeKey: 'shopDrawings',
    submittalsNo: 1,
    revisionNo: 0,
    received: '',
    submitted: '2020-03-23',
    reviewed: '',
    returned: '2020-03-23T21:19:21.315Z',
    status: '',
  },
];

export const dummySubmittalTypeLog = {
  columns: [
    {
      label: 'Attachments',
      key: 'attachments',
      type: 'base',
    },
    {
      label: 'Specification No',
      key: 'specificationNo',
      type: 'base',
    },
    {
      label: 'Specification Description',
      key: 'specificationDescription',
      type: 'base',
    },
    {
      label: 'Trade Name',
      key: 'tradeName',
      type: 'base',
    },
    {
      label: 'Cost Code',
      key: 'costCode',
      type: 'base',
    },
    {
      label: 'Cost Code Description',
      key: 'costCodeDescription',
      type: 'base',
    },
    {
      label: 'Submittal Type',
      key: 'submittalsType',
      type: 'base',
    },
    {
      label: 'Submittal No',
      key: 'submittalsNo',
      type: 'base',
    },
    {
      label: 'Revision No',
      key: 'revisionNo',
      type: 'base',
    },
    {
      label: 'Process Lead Time',
      key: 'processLeadTime',
      type: 'property',
    },
    {
      label: 'Date Required From Trade',
      key: 'dateRequiredFromTrade',
      type: 'property',
    },
    {
      label: 'Date Requested From Trade',
      key: 'dateRequestedFromTrade',
      type: 'property',
    },
    {
      label: 'Date Recieved From Trade',
      key: 'dateReceivedFromTrade',
      type: 'property',
    },
    {
      label: 'Product Lead Time',
      key: 'productLeadTime',
      type: 'property',
    },
    {
      label: 'Date Product Required from Trade',
      key: 'dateProductRequiredOnSite',
      type: 'property',
    },
    {
      label: 'Level of Review',
      key: 'leveOfReview',
      type: 'property',
    },
    {
      label: 'Date Reviewed by YCL',
      key: 'dateReviewedByYCL',
      type: 'property',
    },
    {
      label: 'Date Submitted to Consultant',
      key: 'dateSubmittedToConsultant',
      type: 'property',
    },
    {
      label: 'Date Required from Consultant',
      key: 'dateRequiredFromConsultant',
      type: 'property',
    },
    {
      label: 'Date Recieved from Consultant',
      key: 'dateReceivedFromConsultant',
      type: 'property',
    },
    {
      label: 'Date Returned to Trade',
      key: 'dateReturnedToTrade',
      type: 'property',
    },
    {
      label: 'Status',
      key: 'status',
      type: 'property',
    },
    {
      label: 'Notes',
      key: 'notes',
      type: 'property',
    },
  ],
  rows: [
    {
      id: 1,
      attachments: 0,
      specificationNo: 10000,
      specificationDescription: 'Permit needed for adding toilets',
      tradeName: 'Nightengale Construction',
      tradeId: 6,
      costCode: 13100,
      costCodeDescription: 'Project Manager',
      submittalsType: 'Shop Drawings',
      submittalsNo: 1,
      revisionNo: 0,
      propertyValues: {
        dateReviewedByYCL: '2020-03-25T19:19:28.475Z',
        dateReturnedToTrade: '2020-03-25T19:19:28.496Z',
        dateReceivedFromTrade: '2020-03-25T19:19:28.450Z',
        dateRequiredFromTrade: '2020-03-25T19:19:28.416Z',
        dateRequestedFromTrade: '2020-03-25T19:19:28.438Z',
        dateProductRequiredOnSite: '2020-03-25T19:19:28.465Z',
        dateSubmittedToConsultant: '2020-03-25T19:19:28.480Z',
        dateReceivedFromConsultant: '2020-03-25T19:19:28.491Z',
        dateRequiredFromConsultant: '2020-03-25T19:19:28.485Z',
      },
    },
    {
      id: 3,
      attachments: 0,
      specificationNo: 12356,
      specificationDescription: 'General Construction',
      tradeName: 'Ledcor',
      tradeId: 5,
      costCode: 10000,
      costCodeDescription: 'GENERAL REQUIREMENTS',
      submittalsType: 'Shop Drawings',
      submittalsNo: 2,
      revisionNo: 0,
      propertyValues: {
        dateReviewedByYCL: '2020-03-25T19:20:15.242Z',
        dateReturnedToTrade: '2020-03-25T19:20:15.278Z',
        dateReceivedFromTrade: '2020-03-25T19:20:15.215Z',
        dateRequiredFromTrade: '2020-03-25T19:20:15.195Z',
        dateRequestedFromTrade: '2020-03-25T19:20:15.208Z',
        dateProductRequiredOnSite: '2020-03-25T19:20:15.229Z',
        dateSubmittedToConsultant: '2020-03-25T19:20:15.249Z',
        dateReceivedFromConsultant: '2020-03-25T19:20:15.270Z',
        dateRequiredFromConsultant: '2020-03-25T19:20:15.258Z',
      },
    },
  ],
};

export const dummySubmittalLogAttachments = [
  {
    id: 30,
    submittals_log_entry_id: 40,
    type: 'Attachment',
    directory:
      '/var/www/html/cps/frontend/web/uploads/submittals/submittals-log-entry/40',
    name: 'MM_New_Business.PNG',
  },
  {
    id: 31,
    submittals_log_entry_id: 40,
    type: 'Transmittal',
    directory:
      '/var/www/html/cps/frontend/web/uploads/submittals/submittals-log-entry/40',
    name: 'TradeContract_Transmittal.pdf',
  },
];

// @fixme add dummy data to be used on local development
export const meetingAttendee = [];
export const meetingGroupAttendee = [];
export const meetingGroupItem = [];
export const user = [];
export const meetingSeriesUserPermission = { view: [], edit: [] };
export const lastMeeting = [];
export const company = [];
export const meetingItem = {
  old_business: {
    '': [
      {
        id: '14',
        category_id: '-1',
        number: '2.1',
        subject: 'This is going to be a very long item',
        description:
          'What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?',
        open_date: '2020-03-12',
        due_date: '2020-03-13',
        notes: '',
        status: '0',
        attendees: '[{"id": 6, "name": "Brad Atchison"}]',
        action_date: null,
        action_taken: null,
        action_items:
          '[{"action_date": "2020-03-12", "action_taken": "to be investigated - James"}]',
      },
      {
        id: '44',
        category_id: '-1',
        number: '3.1',
        subject: 'Meeting 3 item 2',
        description: 'Meeting 3 item 2',
        open_date: '2020-03-12',
        due_date: '2020-03-12',
        notes: '',
        status: '0',
        attendees: null,
        action_date: null,
        action_taken: null,
        action_items: null,
      },
      {
        id: '45',
        category_id: '-1',
        number: '3.1',
        subject: 'Meeting 3 item 2',
        description: 'Meeting 3 item 2',
        open_date: '2020-03-12',
        due_date: '2020-03-12',
        notes: '',
        status: '0',
        attendees: '[{"id": 6, "name": "Brad Atchison"}]',
        action_date: null,
        action_taken: null,
        action_items: null,
      },
      {
        id: '46',
        category_id: '-1',
        number: '3.1',
        subject: 'Meeting 3 item 2',
        description: 'Meeting 3 item 2',
        open_date: '2020-03-12',
        due_date: '2020-03-13',
        notes: '',
        status: '0',
        attendees: '[{"id": 6, "name": "Brad Atchison"}]',
        action_date: null,
        action_taken: null,
        action_items: '[{"action_date": "2020-03-12", "action_taken": "1"}]',
      },
      {
        id: '47',
        category_id: '-1',
        number: '3.1',
        subject: 'Meeting 3 item 5',
        description: 'Meeting 3 item 5',
        open_date: '2020-03-12',
        due_date: '2020-03-12',
        notes: '',
        status: '0',
        attendees: '[{"id": 6, "name": "Brad Atchison"}]',
        action_date: null,
        action_taken: null,
        action_items: null,
      },
      {
        id: '49',
        category_id: '-1',
        number: '3.1',
        subject: 'Meeting 3 item 7',
        description: 'Meeting 3 item 7',
        open_date: '2020-03-12',
        due_date: '2020-03-12',
        notes: '',
        status: '0',
        attendees: '[{"id": 6, "name": "Brad Atchison"}]',
        action_date: null,
        action_taken: null,
        action_items: null,
      },
    ],
    Test: [
      {
        id: '15',
        category_id: '12',
        number: '2.2',
        subject: 'test the meeting sequence',
        description:
          'What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?What is the difference between a contact and a guest?',
        open_date: '2020-03-12',
        due_date: '2020-03-12',
        notes: '',
        status: '0',
        attendees: '[{"id": 6, "name": "Brad Atchison"}]',
        action_date: null,
        action_taken: null,
        action_items: '[{"action_date": "2020-03-12", "action_taken": "1"}]',
      },
      {
        id: '42',
        category_id: '12',
        number: '2.3',
        subject: 'Adding new item in meeting 2',
        description: 'meeting 2',
        open_date: '2020-03-12',
        due_date: '2020-03-12',
        notes: '',
        status: '0',
        attendees: null,
        action_date: null,
        action_taken: null,
        action_items: null,
      },
    ],
  },
  new_business: {
    '': [
      {
        id: '51',
        category_id: '-1',
        number: '4.3',
        subject: "Terry's test",
        description: "Terry's 1st meeting",
        open_date: '2020-03-16',
        due_date: '2020-03-16',
        notes: '',
        status: '0',
        attendees: null,
        action_date: null,
        action_taken: null,
        action_items: null,
      },
    ],
  },
};
export const category = [
  { id: '78', subject: 'HR', order: '0' },
  { id: '81', subject: 'Development', order: '1' },
];
export const dummyUsedSubmittalTypes = {};

export const dummyMeetingItemProperties = [
  {
    id: 19,
    type: 3,
    key: 'category',
    configuration: {
      label: 'Category',
      visible: {
        label: 'Visible',
        defaultValue: 1,
      },
      required: {
        label: 'Required',
        defaultValue: 0,
      },
    },
  },
  {
    id: 21,
    type: 3,
    key: 'description',
    configuration: {
      label: 'Description',
      visible: {
        label: 'Visible',
        defaultValue: 1,
      },
      required: {
        label: 'Required',
        defaultValue: 1,
      },
    },
  },
  {
    id: 23,
    type: 3,
    key: 'dueDate',
    configuration: {
      label: 'Due Date',
      visible: {
        label: 'Visible',
        defaultValue: 1,
      },
      required: {
        label: 'Required',
        defaultValue: 0,
      },
    },
  },
  {
    id: 22,
    type: 3,
    key: 'internalNotes',
    configuration: {
      label: 'Notes (internal only)',
      visible: {
        label: 'Visible',
        defaultValue: 0,
      },
    },
  },
  {
    id: 20,
    type: 3,
    key: 'priority',
    configuration: {
      label: 'Priority',
      visible: {
        label: 'Visible',
        defaultValue: 1,
      },
      required: {
        label: 'Required',
        defaultValue: 0,
      },
    },
  },
];

export const dummyRfiPropertiesTable = {
  rfi: [
    {
      type: 'multiline-text',
      key: 'request',
      label: 'Request',
    },
    {
      type: 'date',
      unit: 'day',
      label: 'Date Submitted',
      key: 'dateSubmitted',
    },
    {
      type: 'date',
      unit: 'day',
      label: 'Date Required',
      key: 'dateRequired',
    },
    {
      type: 'date',
      unit: 'day',
      label: 'Date Returned',
      key: 'dateReturned',
    },
    {
      type: 'select',
      label: 'Status',
      options: [
        { label: 'Open', value: 'Open' },
        { label: 'Closed', value: 'Closed' },
      ],
      required: 1,
      key: 'status',
    },
  ],
};

export const dummyRfiLogData = {
  columns: [
    {
      label: 'Attachments',
      key: 'attachments',
      type: 'base',
    },
    {
      label: 'RFI No.',
      key: 'rfiNo',
      type: 'base',
    },
    {
      label: 'Revision No.',
      key: 'revisionNo',
      type: 'base',
    },
    {
      label: 'Description',
      key: 'description',
      type: 'base',
    },
    {
      label: 'Submitted To',
      key: 'submittedTo',
      type: 'base',
    },
    {
      label: 'Submitted By',
      key: 'submittedBy',
      type: 'base',
    },
    {
      label: 'Prepared By',
      key: 'preparedBy',
      type: 'base',
    },
    {
      label: 'Date Submitted',
      key: 'dateSubmitted',
      type: 'property',
    },
    {
      label: 'Date Required',
      key: 'dateRequired',
      type: 'property',
    },
    {
      label: 'Date Returned',
      key: 'dateReturned',
      type: 'property',
    },
    {
      label: 'Status',
      key: 'status',
      type: 'property',
    },
  ],
  rows: [
    {
      id: 1,
      attachments: 0,
      rfiNo: '001',
      revisionNo: 0,
      description: 'Box Culvert Excavation',
      submittedTo: 'Nightengale Construction',
      submittedBy: 'Yellowridge Construction Ltd.',
      preparedBy: 'LaFarge Canada Inc.',
      propertyValues: {
        request: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
            when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
        dateSubmitted: '2020-01-01T19:19:28.475Z',
        dateRequired: '',
        dateReturned: '',
        status: 'Closed',
      },
    },
    {
      id: 2,
      attachments: 1,
      rfiNo: '001',
      revisionNo: 1,
      description: 'Box Culvert Excavation and Exploration',
      submittedTo: 'Nightengale Construction',
      submittedBy: 'Yellowridge Construction Ltd.',
      preparedBy: 'LaFarge Canada Inc.',
      propertyValues: {
        request: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
            when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
        dateSubmitted: '2020-02-01T19:19:28.475Z',
        dateRequired: '',
        dateReturned: '',
        status: 'Open',
      },
    },
    {
      id: 3,
      attachments: 0,
      rfiNo: '002',
      revisionNo: 0,
      description: 'Crane Assembly',
      submittedTo: 'Ledcor Construction',
      submittedBy: 'Yellowridge Construction Ltd.',
      preparedBy: 'Acme Construction Inc.',
      propertyValues: {
        request: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
            when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
        dateSubmitted: '2020-04-02T19:19:28.475Z',
        dateRequired: '2020-05-01T19:19:28.475Z',
        dateReturned: '',
        status: 'Open',
      },
    },
  ],
};

export const dummyProjects = [
  {
    id: 1,
    code: '001',
    name: 'Mount Pleasant Elementary School',
    address: '2300 Guelph St, Vancouver, BC V5T 3P1',
    dateStarted: '2020-01-05T12:22:00.000-07:00',
    dateCompleted: '',
    status: 'Open',
  },
  {
    id: 2,
    code: '002',
    name: 'Metrotown',
    address: '4700 Kingsway, Burnaby, BC V5H 4N2',
    dateStarted: '2019-01-05T12:22:00.000-07:00',
    dateCompleted: '2020-03-05T12:22:00.000-07:00',
    status: 'Closed',
  },
  {
    id: 3,
    code: '003',
    name: 'Blundell Centre',
    address: '8120 No 2 Rd #168, Richmond, BC V7C 5J8',
    dateStarted: '2020-03-10T12:22:00.000-07:00',
    dateCompleted: '',
    status: 'Open',
  },
  {
    id: 4,
    code: '004',
    name: 'Ellsworth',
    address: '209 E 7th Ave, Vancouver, BC V5T 0H3',
    dateStarted: '2018-04-01T12:22:00.000-07:00',
    dateCompleted: '',
    status: 'Open',
  },
  {
    id: 5,
    code: '005',
    name: 'Oakridge Mall',
    address: '650 W 41st Ave, Vancouver, BC V5Z 2M9',
    dateStarted: '2020-01-05T12:22:00.000-07:00',
    dateCompleted: '2020-02-14T12:22:00.000-07:00',
    status: 'Closed',
  },
  {
    id: 6,
    code: '006',
    name: 'Vancouver College',
    address: '5400 Cartier St, Vancouver, BC V6M 3A5',
    dateStarted: '2020-04-01T12:22:00.000-07:00',
    dateCompleted: '',
    status: 'Open',
  },
];

export const dummyRoles = [
  {
    name: 'coordinator',
    label: 'Coordinator',
  },
  {
    name: 'projectManager',
    label: 'Project Manager',
  },
  {
    name: 'employee',
    label: 'Employee',
  },
];

export const dummyUserRoles = {
  coordinator: [
    {
      id: 1,
      name: 'John Doe',
      email: 'jdoe@mailinator.com',
    },
    {
      id: 2,
      name: 'Jane Smith',
      email: 'jsmith@mailinator.com',
    },
  ],
  projectManager: [
    {
      id: 3,
      name: 'Henry Kisser',
      email: 'hkisser@mailinator.com',
    },
    {
      id: 4,
      name: 'Ron Weasley',
      email: 'rweasley@mailinator.com',
    },
  ],
  employee: [
    {
      id: 5,
      name: 'Harry Potter',
      email: 'hpotter@mailinator.com',
    },
    {
      id: 6,
      name: 'Tony Stark',
      email: 'tonystark@mailinator.com',
    },
  ],
};

export const dummyInitialUserRoles = {
  coordinator: {
    id: 2,
    name: 'Jane Smith',
    email: 'jsmith@mailinator.com',
  },

  projectManager: {
    id: 3,
    name: 'Henry Kisser',
    email: 'hkisser@mailinator.com',
  },

  employee: {
    id: 5,
    name: 'Harry Potter',
    email: 'hpotter@mailinator.com',
  },
};
