import React, { useState, useCallback } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Header from './Header';
import SideMenu, { drawerWidth } from './SideMenu';
import { useProject } from '../utils/project';
import clsx from 'clsx';

function gridAreas(...areas) {
  return areas.map(row => `"${row}"`).join('\n');
}

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'grid',
      gridTemplateColumns: `1fr`,
      gridTemplateAreas: gridAreas('main'),
    },
    withSideMenu: {
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: `${drawerWidth}px 1fr`,
        gridTemplateAreas: gridAreas('sidemenu main'),
      },
    },
    header: {
      // @note Yii ajax modal workaround
      // position: 'relative',
      // zIndex: theme.zIndex.appBar,
    },
    sideMenu: {
      gridArea: 'sidemenu',
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      gridArea: 'main',
      overflowX: 'auto',
      // @note This is temporarily disabled for Yii ajax model compatibility
      // position: 'relative',
      // zIndex: 1,
    },
  }),
  { name: 'Layout' }
);

const Layout = props => {
  const {
    LayoutRootComponent = 'div',
    LayoutHeaderComponent = Header,
    LayoutSideMenuComponent = 'div',
    LayoutMainComponent = 'main',
    children,
  } = props;
  const classes = useStyles(props);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);
  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const project = useProject();
  const projectId = project?.id;

  let showSideMenu = false;
  if (
    projectId ||
    (window.location.pathname.includes('/settings') &&
      window.location.pathname !== '/user/settings/profile') || // Temporary workaround until Profile UI is done
    window.location.pathname.includes('/correspondence') ||
    window.location.pathname.includes('/companies') ||
    window.location.pathname.includes('/users')
  ) {
    showSideMenu = true;
  }

  return (
    <>
      <LayoutHeaderComponent
        openDrawer={openDrawer}
        className={classes.header}
      />
      <LayoutRootComponent
        className={clsx(classes.root, showSideMenu && classes.withSideMenu)}
      >
        {showSideMenu && (
          <LayoutSideMenuComponent className={classes.sideMenu}>
            <SideMenu drawerOpen={drawerOpen} onDrawerClose={closeDrawer} />
          </LayoutSideMenuComponent>
        )}
        <Container
          maxWidth="xl"
          component={LayoutMainComponent}
          className={classes.main}
        >
          {children}
        </Container>
      </LayoutRootComponent>
    </>
  );
};

export default Layout;
