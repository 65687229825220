import * as Globalize from 'globalize/dist/globalize-runtime';
import * as intl from './intl';

export type IntlAPI = {
  -readonly [K in keyof typeof intl]: ReturnType<typeof intl[K]>;
} & { locale: string };

/**
 * Generate an Intl API instance
 * Globalize.*Formatter results do not work until locales have been loaded
 * As a result they need to be wrapped in an arrow function that needs
 * to be unwrapped after a locale is loaded and statically set to use.
 */
export function makeIntlAPI(locale: string): IntlAPI {
  Globalize.locale(locale);

  const _intl: { -readonly [K in keyof IntlAPI]: IntlAPI[K] } = Object.create(
    null
  );

  let key: keyof typeof intl;
  for (key in intl) {
    _intl[key] = intl[key]() as any;
  }
  _intl.locale = locale;
  return _intl as IntlAPI;
}
