import React, { memo } from 'react';
import { Container } from '@material-ui/core';
import asErrorBoundary from '../asErrorBoundary';
import UnexpectedError from '../../../routes/errors/UnexpectedError';
import CriticalError from '../../../routes/errors/CriticalError';

/**
 * Error Boundary for site errors that go beyond routes
 */
const SiteError = ({ error, critical = false }) => {
  return (
    <Container>{critical ? <CriticalError /> : <UnexpectedError />}</Container>
  );
};

let SiteErrorBoundary = asErrorBoundary(SiteError);
SiteErrorBoundary = memo(SiteErrorBoundary);

export default SiteErrorBoundary;
