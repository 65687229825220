import React from 'react';
import { Typography, Box } from '@material-ui/core';

/**
 * Layout for a route or page with an error message
 */
const ErrorPageLayout = ({ title, message }) => {
  return (
    <Box my={2}>
      <Typography component="h2" variant="h4">
        {title}
      </Typography>
      <Typography>{message}</Typography>
    </Box>
  );
};

export default ErrorPageLayout;
