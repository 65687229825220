import React, { useState, createContext, useContext, useEffect } from 'react';
import { useUser } from './auth';
// import { useUpdateLocale } from '../../intl';

const CompanyDateFormatContext = createContext(null);

/**
 * Hook that provides the company's date format preference
 */
export function useDateFormat() {
  return useContext(CompanyDateFormatContext);
}

/**
 * Provider that updates locale/etc providers based on the current company
 */
const CompanyPreferencesProvider = ({ children }) => {
  const hasUser = !!useUser()?.username;
  // const updateLocale = useUpdateLocale();
  const [dateFormat, setDateFormat] = useState(null); // default to auto-format by locale

  // @todo Fetch company locale/date prefs, use [user.username] as deps, use updateLocale to update locale and setDateFormat to update date format
  //       Make sure to save this in sessionStorage, with an associated username to avoid cache going stale

  // @hack Hardcode a date format preference until we have company format preferences added to the server
  useEffect(() => {
    if (hasUser) {
      setDateFormat('yyyy-MM-dd');
    }
  }, [hasUser]);

  return (
    <CompanyDateFormatContext.Provider value={dateFormat}>
      {children}
    </CompanyDateFormatContext.Provider>
  );
};

export default CompanyPreferencesProvider;
