import React, { useCallback, useMemo, useLayoutEffect, useRef } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AppRoot from './AppRoot';
import { ThemeProvider } from './components/utils/theme';
import { MockAuthRoot } from './components/utils/auth';
import UtilsProvider from './UtilsProvider';
import { LocaleProvider } from './intl';
import LocaleUtilsProvider from './LocaleUtilsProvider';
import Routes from './Routes';
import nestProviders from './components/utils/nestProviders';
import { MockProjectRoot } from './components/utils/project';
import useConstant from 'use-constant';
import RootErrorBoundary from './components/ui/error-boundaries/RootErrorBoundary';
import SiteErrorBoundary from './components/ui/error-boundaries/SiteErrorBoundary';
import Layout from './components/layout/Layout';
import { useQuery } from '@apollo/client';
import { listProjects, listAllProjects } from './components/utils/api/graphql/Project';

/**
 * Helper for parsing out data placed in <meta property="cps:..." /> elements in the main Yii template
 */
function parseMeta(name, parser) {
  const projectIdStr = document
    .querySelector(`meta[property="cps:${name}"]`)
    .getAttribute('content');
  return parser(projectIdStr);
}

/**
 * Project state provider that uses project state from the Yii application
 */
const YiiProjectRoot = ({ children }) => {
  const projectId = useConstant(() =>
    parseMeta('project-id', idStr => (idStr ? parseInt(idStr) : undefined))
  );
  const projectsData = useQuery(listProjects);
  const projects = projectsData?.data?.listProjects;
  const allProjectsData = useQuery(listAllProjects);
  const allProjects = allProjectsData?.data?.listAllProjects;
  const onChangeProject = useCallback(projectId => {
    window.location = `/project/${encodeURIComponent(projectId)}`;
  }, []);

  return (
    <MockProjectRoot
      allProjects={allProjects}
      projects={projects}
      project={projectId}
      onChangeProject={onChangeProject}
    >
      {children}
    </MockProjectRoot>
  );
};

/**
 * Auth state provider that uses user state from the Yii application
 */
const YiiAuthRoot = ({ children }) => {
  const yiiUser = useConstant(() => parseMeta('user', JSON.parse));
  const sysConfig = useConstant(() => parseMeta('sys-config', JSON.parse));
  const user = useMemo(
    () => ({
      profileLink: sysConfig.minutesOnly
        ? '/account/profile'
        : '/user/settings/profile',
      manageUsersLink: sysConfig.minutesOnly ? '/account' : '/users',
      isGuest: yiiUser.isGuest,
      username: yiiUser.identity && yiiUser.identity.username,
      firstName: yiiUser.identity && yiiUser.identity.first_name,
      lastName: yiiUser.identity && yiiUser.identity.last_name,
      email: yiiUser.identity && yiiUser.identity.email,
      id: yiiUser.identity && yiiUser.identity.id,
    }),
    [sysConfig, yiiUser]
  );
  const onLogOut = useCallback(() => {
    window.location = '/site/logout';
  }, []);

  return (
    <MockAuthRoot user={user} can={yiiUser.can} onLogOut={onLogOut}>
      {children}
    </MockAuthRoot>
  );
};

/**
 * Fallback route that steals the contents from #main for Yii rendered routes
 */
const FallbackRoute = () => {
  const ref = useRef(null);
  useLayoutEffect(() => {
    ref.current.appendChild(document.getElementById('main'));
  }, []);

  return <div ref={ref} id="yii-route" />;
};

/**
 * Variant of <App /> that integrates into the cps-yii2 site
 */
const AppYii = () => {
  return nestProviders(
    <RootErrorBoundary />,
    <UtilsProvider />,
    <LocaleProvider />,
    <LocaleUtilsProvider />,
    // @note We hardcode light here because the legacy pages cannot handle dark mode
    // @todo Remove the override when legacy pages are dealt with
    <ThemeProvider theme="light" />,
    <SiteErrorBoundary critical />,
    <YiiAuthRoot />,
    <AppRoot projectRoot={<YiiProjectRoot />} />,
    <Router basename="/" forceRefresh />,
    <SiteErrorBoundary />,
    <Layout />,
    <Routes extraRoutes={<Route component={FallbackRoute} />} />
  );
};

export default AppYii;
