import clsx from 'clsx';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  AppBar,
  Avatar,
  Container,
  Toolbar,
  TextField,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  withStyles,
  Zoom,
  Button,
  Fade,
} from '@material-ui/core';
import { useInView } from 'react-intersection-observer';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import BuildIcon from '@material-ui/icons/Build';
import LogoutIcon from 'mdi-material-ui/Logout';
import PAB, { PRIMARY_ACTION_BUTTON_HEIGHT } from '../ui/PrimaryActionButton';
import MenuOpener from '../utils/MenuOpener';
import {
  useHeaderContents,
  useHeaderTabs,
  useHeaderCreateAction,
  useHeaderStateInfo,
} from './HeaderContext';
import HeaderErrorBoundary from '../ui/error-boundaries/HeaderErrorBoundary';
import { useAuthActions, useUser, useUserCan } from '../utils/auth';
import { useProjectActions, useProjects, useProject } from '../utils/project';
import NavMenuItem from '../ui/NavMenuItem';
import { dark } from '@material-ui/core/styles/createPalette';
import HeaderAlignment from './HeaderAlignment';
import SideSheetOpener from '../utils/SideSheetOpener';
import UsersIcon from '@material-ui/icons/SupervisedUserCircle';
import OfficeBuildingIcon from 'mdi-material-ui/OfficeBuilding';

export const HEADER_APPBAR_HEIGHT = 64;
export const HEADER_STICKYBAR_HEIGHT = 48;
export const HEADER_TABBAR_HEIGHT = 48;

const useStyles = makeStyles(
  theme => ({
    withHeaderContents: {},
    withStickyHeaderContents: {},
    withTabs: {},
    withCreateAction: {},
    materialHidden: {},
    materialShadow: {},
    stuck: {},
    hidden: {
      display: 'none',
    },
    root: {
      color: theme.palette.getContrastText(theme.palette.background.localShell),
      '& .MuiButton-root': {
        color: dark.text.primary,
      },
      // Hide app bar shadow
      boxShadow: 'none',
    },
    appBar: {
      zIndex: theme.zIndex.appBar + 3,
    },
    headerContentRoot: {
      zIndex: theme.zIndex.appBar + 1,
    },
    headerStickyBarRoot: {
      zIndex: theme.zIndex.appBar + 2,
      top: HEADER_APPBAR_HEIGHT,
      height: HEADER_STICKYBAR_HEIGHT,
      backgroundColor: theme.palette.background.localShell,
    },
    stickyMaterial: {
      zIndex: theme.zIndex.appBar,
      position: 'fixed',
      left: 0,
      right: 0,
      top: HEADER_APPBAR_HEIGHT,
      '&$withStickyHeaderContents': {
        height: HEADER_STICKYBAR_HEIGHT,
      },
      '$withTabs&': {
        height: HEADER_TABBAR_HEIGHT,
      },
      '$withStickyHeaderContents$withTabs&': {
        height: HEADER_STICKYBAR_HEIGHT + HEADER_TABBAR_HEIGHT,
      },
    },
    tabBar: {
      zIndex: theme.zIndex.appBar + 2,
      height: HEADER_TABBAR_HEIGHT,
      top: HEADER_APPBAR_HEIGHT,
      '&$withStickyHeaderContents': {
        top: HEADER_APPBAR_HEIGHT + HEADER_STICKYBAR_HEIGHT,
      },
    },
    headerMaterial: {
      '&:before': {
        content: '""',
        backgroundColor: theme.palette.background.localShell,
        position: 'absolute',
        zIndex: -1,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        '$materialShadow&': {
          boxShadow: theme.shadows[4],
        },
        '$materialHidden&': {
          display: 'none',
        },
        '$headerStickyBarRoot&': {
          top: -HEADER_APPBAR_HEIGHT,
        },
        '$withTabs$headerContentRoot&': {
          bottom: -HEADER_TABBAR_HEIGHT,
        },
        '$withTabs$headerStickyBarRoot&': {
          bottom: -HEADER_TABBAR_HEIGHT,
        },
      },
    },
    noTransition: {
      transition: 'none',
    },
    toolbarSpacer: {
      height: HEADER_APPBAR_HEIGHT,
    },
    toolbar: {
      height: HEADER_APPBAR_HEIGHT,
    },
    subheaderBody: {
      paddingBottom: PRIMARY_ACTION_BUTTON_HEIGHT / 2,
    },
    subheaderContents: {
      padding: theme.spacing(2),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    selectContainer: {
      marginLeft: theme.spacing(4),
    },
    flexSpacer: {
      flex: 1,
    },
    select: {
      minWidth: 100,
      maxWidth: 600,
    },
    title: {
      minWidth: theme.spacing(7),
      marginRight: theme.spacing(2),
      // Link override
      display: 'block',
      color: 'inherit',
      textDecoration: 'none',
      '&:hover, &:active, &:focus': {
        color: dark.text.secondary,
        textDecoration: 'none',
      },
    },
    explorer: {
      position: 'absolute',
      right: 130,
      top: 10,
    },
    avatar: {
      background: theme.palette.primary.main,
    },
    inputRoot: {
      color: 'currentColor',
    },
    pab: {
      position: 'sticky',
      zIndex: theme.zIndex.appBar + 3,
      transform: `translateY(${-48 / 2}px)`,
      paddingLeft: 16, // Match ListItem gutter to align with the side nav
      top: HEADER_APPBAR_HEIGHT,
      height: 0,
      '$withStickyHeaderContents&': {
        top: HEADER_APPBAR_HEIGHT + HEADER_STICKYBAR_HEIGHT,
      },
      '$withTabs&': {
        top: HEADER_APPBAR_HEIGHT + HEADER_TABBAR_HEIGHT,
      },
      '$withStickyHeaderContents$withTabs&': {
        top:
          HEADER_APPBAR_HEIGHT + HEADER_STICKYBAR_HEIGHT + HEADER_TABBAR_HEIGHT,
      },
      ':not($withStickyHeaderContents):not($withTabs)&': {
        top: HEADER_APPBAR_HEIGHT + theme.spacing(2),
        transform: 'none',
      },
    },
    projectDashboard: {
      '&:hover': {
        color: 'inherit',
      },
    },
  }),
  { name: 'header' }
);

/**
 * <Divider /> with color and vertical height modifications for the header's app bar
 */
const HeaderDivider = withStyles(
  {
    root: {
      backgroundColor: 'currentColor',
    },
    vertical: {
      height: 32,
    },
  },
  { name: 'HeaderDivider' }
)(Divider);

/**
 * Page top application bar/toolbar strip
 */
const HeaderAppBar = props => {
  const { openDrawer, className, hasLowerHeader } = props;
  const classes = useStyles(props);

  const { logout } = useAuthActions();
  const user = useUser();
  const can = useUserCan();
  const { isGuest } = user;
  const username = user.username || '';
  const avatarLetter = (user.firstName || user.username || '?')
    .trim()
    .charAt(0)
    .toUpperCase();

  const logOutHandler = useCallback(() => {
    logout();
  }, [logout]);

  const { setProject } = useProjectActions();
  const projects = useProjects();
  const project = useProject();

  const changeProjectHandler = useCallback(
    e => {
      setProject(e.target.value);
    },
    [setProject]
  );

  return (
    <AppBar
      className={clsx(className, classes.appBar, classes.noTransition)}
      position="fixed"
      elevation={hasLowerHeader ? 1 : 4}
      color="default"
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="Open navigation"
          onClick={openDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Link to="/" className={classes.title}>
          <Typography component="h1" variant="h6">
            CPS
          </Typography>
        </Link>

        <HeaderDivider orientation="vertical" />

        {Object.values(can).includes('employee') && (
          <div className={classes.selectContainer}>
            {projects && (
              <TextField
                id="project"
                label={project ? 'Project' : 'Projects'}
                select
                value={(project && project.id) || null}
                onChange={changeProjectHandler}
                className={classes.select}
                variant="outlined"
                size="small"
                InputProps={{ className: classes.inputRoot }}
              >
                {projects?.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </div>
        )}

        <div className={classes.flexSpacer} />

        {isGuest ? (
          <>
            <Button component={Link} to="/user/register">
              Register
            </Button>
            <Button component={Link} to="/user/login">
              Login
            </Button>
          </>
        ) : (
          <>
            {Object.values(can).includes('employee') && (
              <MenuOpener
                name="settingsmenu"
                button={
                  <IconButton
                    color="inherit"
                    aria-label="Settings"
                    className={classes.projectDashboard}
                  >
                    <SettingsIcon />
                  </IconButton>
                }
                menu={
                  <Menu
                    keepMounted
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {Object.values(can).includes('companyAdmin') && (
                      <NavMenuItem icon={<BuildIcon />} to="/settings">
                        Settings
                      </NavMenuItem>
                    )}
                    <NavMenuItem
                      icon={<FormatListBulletedIcon />}
                      to="/correspondence"
                    >
                      Correspondence
                    </NavMenuItem>
                    <NavMenuItem icon={<OfficeBuildingIcon />} to="/companies">
                      Companies
                    </NavMenuItem>
                    {Object.values(can).includes('companyAdmin') && (
                      <NavMenuItem icon={<UsersIcon />} to="/users">
                        Users
                      </NavMenuItem>
                    )}
                  </Menu>
                }
              />
            )}
            <MenuOpener
              name="usermenu"
              button={
                <IconButton
                  aria-label={`${username}'s user menu`}
                  color="inherit"
                >
                  <Avatar className={classes.avatar}>{avatarLetter}</Avatar>
                </IconButton>
              }
              menu={
                <Menu
                  keepMounted
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {Object.values(can).includes('employee') && (
                    <NavMenuItem
                      icon={<PersonIcon />}
                      component={Link}
                      to={{ pathname: '/profile' }}
                    >
                      Profile
                    </NavMenuItem>
                  )}
                  <Divider />
                  <NavMenuItem icon={<LogoutIcon />} onClick={logOutHandler}>
                    Logout
                  </NavMenuItem>
                </Menu>
              }
            />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

/**
 * Dynamic page-specific create action buttton
 */
const PageCreateAction = props => {
  const { className, subheaderInView } = props;
  const classes = useStyles(props);
  const createAction = useHeaderCreateAction();
  const { label, SideSheetComponent, params } = createAction || {};

  // @todo See if we can "remember" the previous label long enough to use Zoom
  //       to animate the old button out and animate the new label in.

  if (!SideSheetComponent) return null;

  return (
    <div className={clsx(className, classes.pab)}>
      <Zoom in={subheaderInView}>
        <SideSheetOpener
          button={<PAB label={label} />}
          sheet={<SideSheetComponent {...params} />}
        />
      </Zoom>
    </div>
  );
};

/**
 * Page top header encompassing the sticky app bar and dynamic page header contents
 */
const Header = props => {
  const { openDrawer, className } = props;

  const {
    static: headerContents,
    sticky: headerStickyContents,
  } = useHeaderContents();
  const headerTabs = useHeaderTabs();
  const {
    hasLowerHeader,
    hasHeaderContent,
    hasStickyHeaderContent,
    hasHeaderTabs,
    hasCreateAction,
  } = useHeaderStateInfo();

  const classes = useStyles(props);
  const [subheaderRef, subheaderInView] = useInView({
    rootMargin: `-${HEADER_APPBAR_HEIGHT + HEADER_STICKYBAR_HEIGHT
      }px 0px 0px 0px`,
  });

  const withClasses = {
    [classes.withHeaderContents]: !!hasHeaderContent,
    [classes.withStickyHeaderContents]: !!hasStickyHeaderContent,
    [classes.withTabs]: !!hasHeaderTabs,
    [classes.withCreateAction]: hasCreateAction,
  };

  return (
    <>
      <HeaderAppBar openDrawer={openDrawer} hasLowerHeader={hasLowerHeader} />
      {headerContents ? (
        <AppBar
          component="div"
          className={clsx(
            className,
            classes.root,
            withClasses,
            classes.headerContentRoot,
            classes.headerMaterial,
            classes.noTransition
          )}
          position="relative"
          elevation={0}
          color="transparent"
          aria-hidden={!subheaderInView} // @fixme Only do this when a sticky header is provided
        >
          <div className={classes.toolbarSpacer} />
          {headerContents && (
            <div
              ref={subheaderRef}
              className={!headerContents ? classes.subheaderBody : null}
            >
              {headerContents && (
                <div className={classes.subheaderContents}>
                  <Container maxWidth="xl" className={classes.selector}>
                    <HeaderErrorBoundary>{headerContents}</HeaderErrorBoundary>
                  </Container>
                </div>
              )}
            </div>
          )}
        </AppBar>
      ) : (
        <div className={clsx(className, classes.toolbarSpacer)} />
      )}
      {hasLowerHeader && (
        <div
          className={clsx(
            className,
            withClasses,
            classes.stickyMaterial,
            classes.headerMaterial,
            classes.materialShadow
          )}
        />
      )}
      {headerStickyContents && (
        <Fade in={!subheaderInView}>
          <AppBar
            component="div"
            className={clsx(
              className,
              classes.root,
              withClasses,
              classes.headerStickyBarRoot,
              classes.noTransition
            )}
            position={hasHeaderContent ? 'fixed' : 'sticky'}
            elevation={0}
            color="transparent"
            aria-hidden={subheaderInView}
          >
            {headerStickyContents}
          </AppBar>
        </Fade>
      )}
      {headerTabs && (
        <AppBar
          component="div"
          className={clsx(
            className,
            classes.root,
            withClasses,
            classes.tabBar,

            classes.noTransition
          )}
          position="sticky"
          elevation={0}
          color="transparent"
        >
          <HeaderAlignment>{headerTabs}</HeaderAlignment>
        </AppBar>
      )}
      <PageCreateAction
        className={clsx(withClasses)}
        subheaderInView={subheaderInView || true}
      />
    </>
  );
};

export default Header;
