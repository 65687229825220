import React from 'react';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useLocaleInfo } from './intl';

/**
 * Root providing utils context dependent on locale context that some libraries depend on
 */
const LocaleUtilsProvider = ({ children }) => {
  const { locale } = useLocaleInfo();

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils} locale={locale}>
      {children}
    </MuiPickersUtilsProvider>
  );
};

export default LocaleUtilsProvider;
