import React, { memo, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import RouteErrorBoundary from './components/ui/error-boundaries/RouteErrorBoundary';
import RouteLoadingBoundary from './components/ui/loading-boundaries/RouteLoadingBoundary';

const MeetingsDashboard = lazy(() => import('./routes/mm/MeetingsDashboard'));
const Meeting = lazy(() => import('./routes/mm/Meeting'));
const SubmittalDashboard = lazy(() =>
  import('./routes/submittal/SubmittalDashboard')
);
const SubmittalLog = lazy(() => import('./routes/submittal/SubmittalLog'));
const ContactGrid = lazy(() => import('./routes/contact/ContactGrid'));
const RequestForInformationDashboard = lazy(() =>
  import('./routes/rfi/RequestForInformationDashboard')
);
const SiDashboard = lazy(() => import('./routes/si/SiDashboard'));
const ProjectsDashboard = lazy(() =>
  import('./routes/project/ProjectsDashboard')
);
const ProjectSummary = lazy(() => import('./routes/project/ProjectSummary'));
const CompaniesDashboard = lazy(() =>
  import('./routes/companies/CompaniesDashboard')
);
const Settings = lazy(() => import('./routes/settings/Settings'));
const CompanyDefaults = lazy(() => import('./routes/company/CompanyDefaults'));
const Profile = lazy(() => import('./routes/profile/Profile'));
const Users = lazy(() => import('./routes/users/Users'));

/**
 * Central handling of page routes
 */
let Routes = ({ extraRoutes }) => {
  return (
    <RouteLoadingBoundary>
      <RouteErrorBoundary>
        <Switch>
          <Route
            exact
            path="/project/:projectId/mm"
            render={props => <MeetingsDashboard {...props} />}
          />
          <Redirect
            exact
            from="/project/:projectId/mm/meeting/:meetingId"
            to="/project/:projectId/mm/meeting/:meetingId/minutes"
          />
          <Route
            exact
            path="/project/:projectId/mm/meeting/:meetingId/minutes"
            render={props => (
              <Meeting
                tab="minutes"
                meetingId={props.match.params.meetingId}
                viewOnly={props.location.state?.viewOnly || false}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/project/:projectId/mm/meeting/:meetingId/attendees"
            render={props => (
              <Meeting
                tab="attendees"
                meetingId={props.match.params.meetingId}
                viewOnly={props.location.state?.viewOnly || false}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/project/:projectId/mm/meeting/:meetingId/correspondence"
            render={props => (
              <Meeting
                tab="correspondence"
                // @fixme Use parseInt and check if Meeting needs fixes for this
                meetingId={props.match.params.meetingId}
                viewOnly={props.location.state?.viewOnly || false}
                {...props}
              />
            )}
          />
          <Redirect
            exact
            from="/project/:projectId/submittals"
            to="/project/:projectId/submittals/logs"
          />
          <Route
            exact
            path="/project/:projectId/submittals/logs"
            render={props => <SubmittalDashboard tab="logs" {...props} />}
          />
          <Route
            exact
            path="/project/:projectId/submittals/checklist"
            render={props => <SubmittalDashboard tab="checklist" {...props} />}
          />
          <Route
            exact
            path="/project/:projectId/submittals/defaults"
            render={props => <SubmittalDashboard tab="defaults" {...props} />}
          />
          <Route
            exact
            path="/project/:projectId/submittals/log/:submittalType"
            render={props => (
              <SubmittalLog
                {...props}
                submittalType={props.match.params.submittalType}
              />
            )}
          />

          <Redirect
            exact
            from="/project/:projectId/si"
            to="/project/:projectId/si/logs"
          />
          <Route
            exact
            path="/project/:projectId/si/logs"
            render={props => <SiDashboard tab="logs" {...props} />}
          />
          <Route
            exact
            path="/project/:projectId/si/defaults"
            render={props => <SiDashboard tab="defaults" {...props} />}
          />
          <Route
            exact
            path="/project/:projectId/contacts"
            render={props => <ContactGrid {...props} />}
          />
          <Redirect
            exact
            from="/project/:projectId/rfi"
            to="/project/:projectId/rfi/logs"
          />
          <Route
            exact
            path="/project/:projectId/rfi/logs"
            render={props => (
              <RequestForInformationDashboard tab="logs" {...props} />
            )}
          />
          <Route
            exact
            path="/project/:projectId/rfi/defaults"
            render={props => (
              <RequestForInformationDashboard tab="defaults" {...props} />
            )}
          />
          <Redirect
            exact
            from="/correspondence"
            to="/correspondence/signature"
          />
          <Route
            exact
            path="/correspondence/signature"
            render={props => <CompanyDefaults tab="signature" {...props} />}
          />
          <Route
            exact
            path="/correspondence/defaults"
            render={props => <CompanyDefaults tab="defaults" {...props} />}
          />
          <Route
            exact
            path="/project"
            render={props => <ProjectsDashboard {...props} />}
          />
          <Route
            exact
            path="/project/:projectId"
            render={props => (
              <ProjectSummary
                {...props}
                projectId={props.match.params.projectId}
              />
            )}
          />
          <Route
            exact
            path="/companies"
            render={props => <CompaniesDashboard {...props} />}
          />
          <Route
            exact
            path="/settings"
            render={props => <Settings {...props} />}
          />
          <Route
            exact
            path="/profile"
            render={props => <Profile {...props} />}
          />
          <Route exact path="/users" render={props => <Users {...props} />} />
          {extraRoutes}
        </Switch>
      </RouteErrorBoundary>
    </RouteLoadingBoundary>
  );
};
Routes = memo(Routes);

export default Routes;
