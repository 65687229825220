import { navigatorLanguages, lookup } from 'langtag-utils';
import { supportedLocales } from './supportedLocales';

/**
 * Validate a locale or list of locales
 * and return the best matching supported locale.
 * Takes various ways of expressing locales into account, e.g. turning `en-US` into `en`
 */
export const lookupLocale = (locale: string | string[]): string => {
  // Longer locales like `en-CA` need to be sorted before smaller locales like `en`
  // Otherwise the more specific locale will never be reached.
  const tags = supportedLocales.slice().sort().reverse();
  return lookup(tags, locale, 'en');
};

/**
 * Looks at the navigator locale information and picks the first supported locale.
 * Falls back to `en` if none are supported.
 */
export const detectLocale = (): string => {
  if (process.env.NODE_ENV === 'development') {
    const localeOverride = sessionStorage.getItem('locale-override');
    if (localeOverride) {
      return lookupLocale(localeOverride.split(','));
    }
  }

  return lookupLocale(navigatorLanguages());
};
