import React, { ReactNode, FC } from 'react';
import QS from 'qs';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
// import { onError } from 'apollo-link-error';
import { camelCase } from 'camel-case';
// import { snakeCase } from 'snake-case';
import { useMemo } from 'react';
import { transformData } from '../utils/transformData';
import { getMockApiResponse } from '../api.mock';

const mockFetcher: RestLink.CustomFetch = async (
  request: RequestInfo,
  init: RequestInit
) => {
  if (typeof request !== 'string' || !init)
    throw new TypeError('Unexpected request/init type');
  const { pathname, search } = new URL(request as string, window.location.href);
  return new Response(
    JSON.stringify(
      getMockApiResponse(
        init.method || 'GET',
        pathname,
        QS.parse(search),
        init.body
      )
    )
  );
};

export function makeClient(): ApolloClient<any> {
  const restLink = new RestLink({
    uri: '/api', // @todo Use overridable variable
    fieldNameNormalizer: (key: string) => camelCase(key),
    // @fixme: can uncomment when rework for the server side code to handle snake_cased keys
    // for nested objects is complete
    // fieldNameDenormalizer: (key: string) => snakeCase(key),
    customFetch:
      process.env.NODE_ENV === 'development' &&
      typeof (window as any).yii === 'undefined'
        ? mockFetcher
        : undefined,
    typePatcher: {
      MeetingGroup: data =>
        transformData(
          {
            mapData: {},
            parseData: {
              id: parseInt,
              companyId: parseInt,
              projectId: parseInt,
            },
          },
          data
        ),
      MeetingGroupInfo: data =>
        transformData(
          {
            mapData: {},
            parseData: {
              id: parseInt,
              openItems: parseInt,
            },
          },
          data
        ),
    },
  });

  return new ApolloClient({
    link: ApolloLink.from([
      // @todo https://www.apollographql.com/docs/react/migrating/boost-migration/#after-1
      //       Add error handling and auth/network state?
      restLink,
    ]),
    cache: new InMemoryCache(),
  });
}

export interface GqlApiProviderProps {
  children: ReactNode;
}

/**
 * GraphQL API client provider
 */
export const GqlApiProvider: FC<GqlApiProviderProps> = ({ children }) => {
  const client = useMemo(() => makeClient(), []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
