import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import YiiApp from './AppYii';
import * as serviceWorker from './serviceWorker';

if (window.__webpack_public_path__) {
  // eslint-disable-next-line no-native-reassign, no-undef
  __webpack_public_path__ = window.__webpack_public_path__;
}

if (
  process.env.NODE_ENV === 'development' &&
  require('./yii-dev-bridge').active
) {
  require('./yii-dev-bridge').connect();
} else if (window.yii) {
  // Yii2 integrated app
  const app = document.getElementById('react-app');
  ReactDOM.render(<YiiApp />, app);
} else {
  // Full App with CRA at the root
  ReactDOM.render(<App />, document.getElementById('root'));
}

serviceWorker.unregister();
