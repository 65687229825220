import { match } from 'path-to-regexp';
import { mockResponses } from './defs/api.mock.defs';

export type MockResponse = (
  params: Record<string, string>,
  query: any,
  body?: any
) => any;

// Map mockResponses definitions to an array of matchers and mock response creators
const mockQueryRoutes = Object.entries(mockResponses).map(
  ([httpLine, response]) => {
    const [, method, path] = httpLine.match(/^(\w+) (.+)$/) ?? [];

    return {
      method,
      pathPattern: path,
      match: match(path),
      response,
    };
  }
);

/**
 * Get mock response data for an API method call
 * Takes the API method path and query data.
 */
export function getMockApiResponse(
  method: string,
  path: string,
  query: any,
  body?: any
) {
  for (const route of mockQueryRoutes) {
    const match = route.match(path);
    if (method === route.method && match) {
      return route.response(
        match.params as Record<string, string>,
        query,
        body
      );
    }
  }

  throw new Error(`No mock response for API method: ${method} ${path}`);
}
