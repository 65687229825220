import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';

/**
 * Top-level root providing utils context that some libraries depend on
 */
const UtilsProvider = ({ children }) => {
  return (
    <HelmetProvider>
      <SnackbarProvider>{children}</SnackbarProvider>
    </HelmetProvider>
  );
};

export default UtilsProvider;
