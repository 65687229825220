/* eslint-disable */
var Globalize = require("globalize/dist/globalize-runtime");
require("globalize/dist/globalize-runtime/number");
require("globalize/dist/globalize-runtime/date");

var numberRound = Globalize._numberRound;
var numberToPartsFormatterFn = Globalize._numberToPartsFormatterFn;
var numberFormatterFn = Globalize._numberFormatterFn;
var dateToPartsFormatterFn = Globalize._dateToPartsFormatterFn;
var dateFormatterFn = Globalize._dateFormatterFn;

Globalize.a821123423 = numberToPartsFormatterFn(["",,1,0,0,,,,,,"","0","-0","-","",numberRound(),"∞","NaN",{".":".",",":",","%":"%","+":"+","-":"-","E":"E","‰":"‰"},]);
Globalize.b314646955 = numberToPartsFormatterFn(["",,2,0,0,,,,,,"","00","-00","-","",numberRound(),"∞","NaN",{".":".",",":",","%":"%","+":"+","-":"-","E":"E","‰":"‰"},]);
Globalize.a1378886668 = numberFormatterFn(Globalize("en").numberToPartsFormatter({"raw":"0"}));
Globalize.b203855544 = numberFormatterFn(Globalize("en").numberToPartsFormatter({"raw":"00"}));
Globalize.b683761904 = dateToPartsFormatterFn({"1":Globalize("en").numberFormatter({"raw":"0"})}, {"pattern":"MMMM d, y","timeSeparator":":","months":{"M":{"4":{"1":"January","2":"February","3":"March","4":"April","5":"May","6":"June","7":"July","8":"August","9":"September","10":"October","11":"November","12":"December"}}}});
Globalize.a2122364151 = dateToPartsFormatterFn({"1":Globalize("en").numberFormatter({"raw":"0"})}, {"pattern":"MMM d, y","timeSeparator":":","months":{"M":{"3":{"1":"Jan","2":"Feb","3":"Mar","4":"Apr","5":"May","6":"Jun","7":"Jul","8":"Aug","9":"Sep","10":"Oct","11":"Nov","12":"Dec"}}}});
Globalize.a2011541116 = dateToPartsFormatterFn({"1":Globalize("en").numberFormatter({"raw":"0"}),"2":Globalize("en").numberFormatter({"raw":"00"})}, {"pattern":"M/d/yy","timeSeparator":":"});
Globalize.a352055019 = dateToPartsFormatterFn({}, {"pattern":"LLLL","timeSeparator":":","months":{"L":{"4":{"1":"January","2":"February","3":"March","4":"April","5":"May","6":"June","7":"July","8":"August","9":"September","10":"October","11":"November","12":"December"}}}});
Globalize.b542870291 = dateToPartsFormatterFn({}, {"pattern":"LLL","timeSeparator":":","months":{"L":{"3":{"1":"Jan","2":"Feb","3":"Mar","4":"Apr","5":"May","6":"Jun","7":"Jul","8":"Aug","9":"Sep","10":"Oct","11":"Nov","12":"Dec"}}}});
Globalize.b697094635 = dateToPartsFormatterFn({"1":Globalize("en").numberFormatter({"raw":"0"})}, {"pattern":"d","timeSeparator":":"});
Globalize.b696469024 = dateToPartsFormatterFn({"1":Globalize("en").numberFormatter({"raw":"0"})}, {"pattern":"y","timeSeparator":":"});
Globalize.b1628081667 = dateToPartsFormatterFn({"1":Globalize("en").numberFormatter({"raw":"0"}),"2":Globalize("en").numberFormatter({"raw":"00"})}, {"pattern":"h:mm a","timeSeparator":":","dayPeriods":{"am":"AM","pm":"PM"}});
Globalize.a218160295 = dateFormatterFn(Globalize("en").dateToPartsFormatter({"date":"long"}));
Globalize.a1286203598 = dateFormatterFn(Globalize("en").dateToPartsFormatter({"date":"medium"}));
Globalize.b93641787 = dateFormatterFn(Globalize("en").dateToPartsFormatter({"date":"short"}));
Globalize.b39352062 = dateFormatterFn(Globalize("en").dateToPartsFormatter({"skeleton":"LLLL"}));
Globalize.b694043658 = dateFormatterFn(Globalize("en").dateToPartsFormatter({"skeleton":"LLL"}));
Globalize.a1492689758 = dateFormatterFn(Globalize("en").dateToPartsFormatter({"skeleton":"d"}));
Globalize.a1493315369 = dateFormatterFn(Globalize("en").dateToPartsFormatter({"skeleton":"y"}));
Globalize.a561702726 = dateFormatterFn(Globalize("en").dateToPartsFormatter({"time":"short"}));

module.exports = Globalize;
