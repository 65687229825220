import React, { cloneElement, memo } from 'react';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import useId from './useId';

/**
 * Helper to handle open a menu when a button is clicked
 */
let MenuOpener = ({ name, button, menu }) => {
  const popupId = useId(name);
  const popupState = usePopupState({ variant: 'popover', popupId });

  return (
    <>
      {cloneElement(button, bindTrigger(popupState))}
      {cloneElement(menu, {
        ...bindMenu(popupState),
        onClick: popupState.close,
      })}
    </>
  );
};
MenuOpener = memo(MenuOpener);

export default MenuOpener;
