import { createContext } from 'react';
import { defaultLocaleAPI } from './loader';
import { IntlAPI } from './api';

export type UpdateLocale = (locale: string) => void;
export const UpdateLocaleContext = createContext<UpdateLocale>(() => {});
export const LocaleContext = createContext<string>('zz');
export const IntlContext = createContext<Promise<IntlAPI>>(
  Promise.resolve(defaultLocaleAPI)
);
