import { lookupLocale } from './locale';
import { IntlAPI, makeIntlAPI } from './api';
import { supportedLocales } from './supportedLocales';

// Hardcode "English (US)" into the main bundle
// This guarantees us that formatters for the default language will always
// be available and provides an alternative to displaying nothing while
// the actual locale is loading.
import './lang/en';

/**
 * Import a locale, returns a Promise that resolves when it's ready
 */
async function importLocale(locale: string): Promise<void> {
  if (!supportedLocales.includes(locale))
    throw new Error(`Invalid locale: ${locale}`);
  await import(`./lang/${locale}.js`);
}

/**
 * Import a locale and return a promise that resolves with an IntlAPI.
 */
async function loadLocaleAPI(locale: string): Promise<IntlAPI> {
  await importLocale(locale);
  return makeIntlAPI(locale);
}

export const defaultLocaleAPI = makeIntlAPI('en');

const localeCache: Record<string, Promise<IntlAPI>> = Object.create(null);
localeCache.en = Promise.resolve(defaultLocaleAPI);

/**
 * Return a Promise that resolves with the API for a locale
 */
export function getLocaleAPI(rawLocale: string) {
  const locale = lookupLocale(rawLocale);
  if (!(locale in localeCache)) {
    localeCache[locale] = loadLocaleAPI(locale);
  }

  return localeCache[locale];
}
