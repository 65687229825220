import { camelCase } from 'camel-case';
import { ApiResponseTransformInfo } from '../api.interfaces';

function recursiveTransformData(
  methodInfo: ApiResponseTransformInfo,
  data: any
): any {
  if (Array.isArray(data)) {
    return data.map(item => recursiveTransformData(methodInfo, item));
  }

  const res: any = {};

  for (const srcKey in data) {
    const parse = methodInfo?.parseData?.[srcKey] || (value => value);
    const destKey = methodInfo?.mapData?.[srcKey] || camelCase(srcKey);
    res[destKey] = parse(data[srcKey]);
  }

  return res;
}

/**
 * Transforms data from API responses to the result objects useQuery returns
 * - If the data is an array operates on each array item,
 *   otherwise operates on the top level object returned by the api.
 * - Uses `mapData` definitions to rename specific properties,
 *   and uses camelCase to change the case of the rest.
 * - Uses any `parseData` parser to convert the value of the associated property.
 */
export function transformData(
  methodInfo: ApiResponseTransformInfo,
  data: any
): any {
  if (methodInfo.preTransformData) {
    data = methodInfo.preTransformData(data);
  }

  return recursiveTransformData(methodInfo, data);
}
