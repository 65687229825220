import preval from 'preval.macro';

export interface LocaleInfo {
  locale: string;
  name: string;
  dir: 'ltr' | 'rtl';
}

const localeInfo: Record<
  string,
  LocaleInfo
> = preval`module.exports = require('./localeInfo.preval');`;

export default localeInfo;
