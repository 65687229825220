import React, { ReactNode, FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { LocaleContext, UpdateLocaleContext } from './context';
import { detectLocale } from './locale';
import { useLocaleInfo } from './hooks';

export interface LocaleRootProps {
  children: ReactNode;
}

/**
 * Intl root that handle html attributes
 */
const IntlRoot: FC<LocaleRootProps> = ({ children }) => {
  const { locale, dir } = useLocaleInfo();

  return (
    <>
      <Helmet>
        <html lang={locale} dir={dir} />
      </Helmet>
      {children}
    </>
  );
};

export interface LocaleProviderProps {
  children: ReactNode;
}

/**
 * Production locale provider
 * - Auto-detects the supported locale that best matches the browser locale.
 * - Supports development overrides
 */
export const LocaleProvider: FC<LocaleProviderProps> = ({ children }) => {
  const [locale, setLocale] = useState(detectLocale);

  // @todo Move development overrides here? (Experiment with storage events)

  return (
    <UpdateLocaleContext.Provider value={setLocale}>
      <LocaleContext.Provider value={locale}>
        <IntlRoot>{children}</IntlRoot>
      </LocaleContext.Provider>
    </UpdateLocaleContext.Provider>
  );
};

export interface MockLocaleProviderProps {
  locale: string;
  children: ReactNode;
}

/**
 * Mock locale provider that provides a static locale value from is props
 * innstead of the user's real locale.
 */
export const MockLocaleProvider: FC<MockLocaleProviderProps> = ({
  children,
  locale,
}) => {
  if (locale === 'auto') {
    locale = detectLocale();
  }

  return (
    <LocaleContext.Provider value={locale}>
      <IntlRoot>{children}</IntlRoot>
    </LocaleContext.Provider>
  );
};
