import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  highlight: {
    background: theme.palette.primary.light,
    borderRadius: '0px 25px 25px 0px',
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

const MenuItem = props => {
  const classes = useStyles();
  const { link, active, page, icon, primary } = props;

  return (
    <div>
      <ListItem
        button
        component={Link}
        to={link}
        className={active === page ? classes.highlight : null}
      >
        <ListItemIcon className={active === page ? classes.icon : null}>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={primary}
          className={active === page ? classes.icon : null}
        />
      </ListItem>
    </div>
  );
};
export default MenuItem;
