import React, { Component } from 'react';
import { getDisplayName } from 'recompose';

// Partially inspired by https://github.com/bvaughn/react-error-boundary/blob/master/src/ErrorBoundary.js
class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { children, FallbackErrorComponent, ...fallbackProps } = this.props;
    const { error } = this.state;

    if (error !== null) {
      return <FallbackErrorComponent {...fallbackProps} error={error} />;
    }

    return children || null;
  }
}

/**
 * HOC-like wrapper that creates an ErrorBoundary component with a
 */
export default function asErrorBoundary(FallbackErrorComponent) {
  const ComponentErrorBoundary = ({ children, ...boundaryProps }) => {
    return (
      <ErrorBoundary
        {...boundaryProps}
        FallbackErrorComponent={FallbackErrorComponent}
      >
        {children}
      </ErrorBoundary>
    );
  };
  ComponentErrorBoundary.displayName =
    getDisplayName(FallbackErrorComponent).replace(/Error$/, '') +
    'ErrorBoundary';

  return ComponentErrorBoundary;
}
