import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const Confirmation = props => {
  const {
    dialogOpen,
    handleClose,
    params,
    action,
    dialogTitle,
    dialogContent,
    primaryButtonLabel,
    altButtonAction,
    altButtonLabel,
  } = props;
  const [open, setOpen] = useState(dialogOpen);

  useEffect(() => {
    setOpen(dialogOpen);
  }, [setOpen, dialogOpen]);

  const handleSubmit = useCallback(async () => {
    handleClose();
    await action(params);
  }, [action, params, handleClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {altButtonAction && (
          <Button onClick={altButtonAction} color="primary">
            {altButtonLabel ? altButtonLabel : 'Alt Action'}
          </Button>
        )}
        <Button onClick={handleSubmit} color="primary" autoFocus>
          {primaryButtonLabel ? primaryButtonLabel : 'Continue'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;
